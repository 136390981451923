import { useMemo } from 'react';
import { VDMS, DSS, CAS } from '../constants';

const FIELDS_NAMES = {
  UA: 'Device UA',
  DNT: 'Device DNT',
  LMT: 'Device LMT',
  IP: 'Device IP',
  IPV6: 'Device IPv6',
  DEVICE_TYPE: 'Device Device Type',
  MAKE: 'Device Make',
  MODEL: 'Device Model',
  OS: 'Device OS',
  OSV: 'Device OSV',
  HEIGHT: 'Device Height',
  WIDTH: 'Device Width',
  IFA: 'Device IFA',
  IFA_TYPE: 'Device IFA Type',
  COMPUTER_GUID: 'Device Computer GUID',
  ID: 'Device ID',
  PARTNER: 'Device Partner',
  PLATFORM: 'Device Platform',
  SESSION_ID: 'Device Session ID',
};

const DEFAULT_SUPPLY_SOURCE_DEVICE_DETAILS = [
  { label: FIELDS_NAMES.UA, value: '-' },
  { label: FIELDS_NAMES.DNT, value: '-' },
  { label: FIELDS_NAMES.LMT, value: '-' },
  { label: FIELDS_NAMES.IP, value: '-' },
  { label: FIELDS_NAMES.IPV6, value: '-' },
  { label: FIELDS_NAMES.DEVICE_TYPE, value: '-' },
  { label: FIELDS_NAMES.MAKE, value: '-' },
  { label: FIELDS_NAMES.MODEL, value: '-' },
  { label: FIELDS_NAMES.OS, value: '-' },
  { label: FIELDS_NAMES.OSV, value: '-' },
  { label: FIELDS_NAMES.HEIGHT, value: '-' },
  { label: FIELDS_NAMES.WIDTH, value: '-' },
  { label: FIELDS_NAMES.IFA, value: '-' },
  { label: FIELDS_NAMES.IFA_TYPE, value: '-' },
  { label: FIELDS_NAMES.COMPUTER_GUID, value: '-' },
  { label: FIELDS_NAMES.ID, value: '-' },
  { label: FIELDS_NAMES.PARTNER, value: '-' },
  { label: FIELDS_NAMES.PLATFORM, value: '-' },
  { label: FIELDS_NAMES.SESSION_ID, value: '-' },
];

const vdmsSupplySourceDeviceDetails = (data) => [
  { label: FIELDS_NAMES.UA, value: data?.ua },
  { label: FIELDS_NAMES.DNT, value: data?.dnt },
  { label: FIELDS_NAMES.LMT, value: data?.lmt },
  { label: FIELDS_NAMES.IP, value: data?.ip },
  { label: FIELDS_NAMES.IFA, value: data?.ifa },
  { label: FIELDS_NAMES.IFA_TYPE, value: data?.ifatype },
];

const dssSupplySourceDeviceDetails = (data) => [
  { label: FIELDS_NAMES.UA, value: data?.ua },
  { label: FIELDS_NAMES.DNT, value: data?.dnt },
  { label: FIELDS_NAMES.LMT, value: data?.lmt },
  { label: FIELDS_NAMES.IP, value: data?.ip },
  { label: FIELDS_NAMES.IPV6, value: data?.ipv6 },
  { label: FIELDS_NAMES.DEVICE_TYPE, value: data?.devicetype },
  { label: FIELDS_NAMES.MAKE, value: data?.make },
  { label: FIELDS_NAMES.MODEL, value: data?.model },
  { label: FIELDS_NAMES.OS, value: data?.os },
  { label: FIELDS_NAMES.OSV, value: data?.osv },
  { label: FIELDS_NAMES.HEIGHT, value: data?.h },
  { label: FIELDS_NAMES.WIDTH, value: data?.w },
  { label: FIELDS_NAMES.IFA, value: data?.ifa },
  { label: FIELDS_NAMES.IFA_TYPE, value: data?.ifatype },
  { label: FIELDS_NAMES.SESSION_ID, value: data?.sessionid },
];

const casSupplySourceDeviceDetails = (data) => {
  const deviceDataV2 = data?.['device-info'];
  const deviceDataV3 = data?.device;
  return [
    { label: 'Device User Agent', value: deviceDataV2?.['user-agent'] || deviceDataV3?.['user-agent'] },
    {
      label: 'Device Limit Ad Tracking',
      value: deviceDataV2?.['device-dnt'].toString() || deviceDataV3?.['limit-ad-tracking'],
    },
    { label: FIELDS_NAMES.LMT, value: deviceDataV2?.['device-dnt'].toString() || deviceDataV3?.['limit-ad-tracking'] },
    { label: 'Device IPv4', value: deviceDataV2?.['ip-address'] || deviceDataV3?.['ip-v4-address'] },
    { label: FIELDS_NAMES.IPV6, value: deviceDataV3?.['ip-v6-address'] },
    { label: FIELDS_NAMES.DEVICE_TYPE, value: deviceDataV2?.device || deviceDataV3?.name },
    { label: 'Device Ad ID', value: deviceDataV2?.['device-ad-id'] || deviceDataV3?.['ad-id'] },
    { label: FIELDS_NAMES.IFA_TYPE, value: deviceDataV3?.['ifa-type'] },
    { label: FIELDS_NAMES.ID, value: deviceDataV2?.['device-id'] || deviceDataV3?.['ad-id'] },
    { label: FIELDS_NAMES.PARTNER, value: deviceDataV2?.['distribution-partner'] || data?.content?.distributor },
    { label: FIELDS_NAMES.PLATFORM, value: '-' },
  ];
};

const supplySourceMap = {
  [VDMS]: vdmsSupplySourceDeviceDetails,
  [DSS]: dssSupplySourceDeviceDetails,
  [CAS]: casSupplySourceDeviceDetails,
};

const resolveSupplySourceDeviceDetails = (type, data) => {
  const fn = supplySourceMap[type];
  return fn ? fn(data) : DEFAULT_SUPPLY_SOURCE_DEVICE_DETAILS;
};

export const useSupplySourceDeviceDetailsResolver = (type, data) => {
  const dataDetail = type === CAS ? data : data?.device;
  return useMemo(() => resolveSupplySourceDeviceDetails(type, dataDetail), [type, dataDetail]);
};
