import { notification } from 'antd';
import format from 'xml-formatter';
import moment from 'moment';
import momentTz from 'moment-timezone';

import {
  acceptSharedTestCase,
  deleteSharedTestCase,
  deleteTestCase,
  getTestCasesByUserId,
  getTracesByUserIdAndTraceIds,
  shareTestCase,
  updateTestCase,
} from '../../services/adsTracer';
import { ADS_TRACER, NA } from '../../constants';
import { arrToMap, getLocalTimezoneAbbreviation } from '../../utils';
import { accountProfileCombinationsSelector } from './newTestCase';
import { pathParamsSelector } from '../app';
import { OUTPUT_TIME_FORMAT } from '../../pages/AdsTracer/constants';
import { routerRedux } from 'dva/router';

export const adsTracer = {
  namespace: 'adsTracer',
  state: {
    rangeFilter: null,
    testCases: [],
    traces: [],
    summariesFilters: {},
    summariesWidths: {},
    testCaseWidths: {},
  },
  reducers: {
    updateTestCases(state, { payload: testCases }) {
      return { ...state, testCases };
    },
    updateTraces(state, { payload: traces }) {
      return { ...state, traces };
    },
    updateRangeFilter(state, { payload: rangeFilter }) {
      return { ...state, rangeFilter };
    },
    updateSummariesFilters(state, { payload: summariesFilters }) {
      return { ...state, summariesFilters };
    },
    updateSummariesWidths(state, { payload: summariesWidths }) {
      return { ...state, summariesWidths };
    },
    updateTestCaseWidths(state, { payload: testCaseWidths }) {
      return { ...state, testCaseWidths };
    },
  },
  effects: {
    *pageInit({ payload: { pathParams } }, { put, take }) {
      // Get Hulu Accounts to decorate test case info
      yield put({ type: 'newTestCase/fetchLinkedHuluAccounts' });
      yield take('newTestCase/fetchLinkedHuluAccounts/@@end');

      // Get Test Cases
      yield put({ type: 'fetchTestCases' });
      yield take('fetchTestCases/@@end');

      // Handle any url state params
      if (pathParams?.testCaseId) {
        yield put({ type: 'fetchTraces', payload: pathParams.testCaseId });
      }
    },
    *fetchTestCases(emptyPayload, { call, put, select }) {
      const resp = yield call(getTestCasesByUserId);

      // Fetch Test Cases from Ads Tracer
      if (resp?.status === 200 && resp?.data) {
        // Enhance Test Case info if account linked
        const fetchedLinkedAccounts = yield select(accountProfileCombinationsSelector);
        const mappedAccounts = arrToMap(fetchedLinkedAccounts, 'profileId');

        const testCases = resp.data['test-cases'].map((testCase) => {
          const profileId = testCase['profile-id'];
          const accountId = testCase['account-id'];
          return {
            testCaseId: testCase['test-case-id'],
            expireAt: moment.utc(testCase['end-time']).local().format(OUTPUT_TIME_FORMAT),
            createdAt: moment.utc(testCase['created-time']).local().format(OUTPUT_TIME_FORMAT),
            status: testCase.status,
            // If owner, get info from account data
            ...((testCase['owner'] && {
              ...mappedAccounts[accountId + '#' + profileId],
              isSharedToOther: testCase['shared'],
              accountId,
              profileId,
              profileName: mappedAccounts[accountId + '#' + profileId]?.['profileName'] ?? profileId,
            }) || {
              // If shared, get info straight froms data
              isSharedToMe: true,
              accountName: testCase['test-case-owner-user-name'],
              profileName: testCase['test-case-owner-profile-name'],
              sharedTime: moment.utc(testCase['share-accept-time']).local().format(OUTPUT_TIME_FORMAT),
            }),
          };
        });

        yield put({ type: 'updateTestCases', payload: testCases });
      } else if (resp?.status === 204) {
        yield put({ type: 'updateTestCases', payload: [] });
      }
    },
    *fetchTraces({ payload: testCaseId }, { call, put }) {
      const resp = yield call(getTracesByUserIdAndTraceIds, testCaseId);

      if (resp?.data) {
        let traceData = [];
        for (const [index, trace] of resp.data.entries()) {
          const { 'ads-spans': preformattedAdsSpans, metadata } = trace;
          let requestEntryTime;
          let requestEntryStatus;

          // Process Ad Spans
          const adsSpans = preformattedAdsSpans.map(
            ({
              'ad-server': adserver,
              'request-uri': reqUri,
              'request-headers': reqHeaders,
              'request-body': preformattedReqBody,
              'response-headers': respHeaders,
              'response-body': preformattedRespBody,
              'status-code': respStatus,
              ...spanData
            }) => {
              let reqBody;
              let respBody;
              let payloadType;

              // Try parsing as JSON, otherwise try as XML
              try {
                reqBody = JSON.stringify(JSON.parse(preformattedReqBody || '{}'), null, 2);
                respBody = JSON.stringify(JSON.parse(preformattedRespBody || '{}'), null, 2);
                payloadType = 'json';
              } catch (err) {
                try {
                  reqBody = format(preformattedReqBody);
                } catch (errReq) {
                  reqBody = preformattedReqBody;
                }
                try {
                  respBody = format(preformattedRespBody);
                } catch (errRes) {
                  respBody = preformattedRespBody;
                }
                payloadType = 'xml';
              }
              reqHeaders = JSON.stringify(JSON.parse(reqHeaders || '{}'), null, 2);
              respHeaders = JSON.stringify(JSON.parse(respHeaders || '{}'), null, 2);
              respStatus = JSON.stringify(JSON.parse(respStatus || '{}'), null, 2);
              const time = momentTz(spanData.timestamp / 1000)
                .local()
                .format(OUTPUT_TIME_FORMAT);

              // record the entry time and status of a request when entering one of the following services:
              // UP Adapter, Hululive Adapter, or Huluvod Adapter
              if (time && spanData?.kind === 'SERVER' && (adserver === 'HULU' || adserver === 'DAS')) {
                requestEntryTime = time;
                requestEntryStatus = respStatus;
              }
              const spanMetadata = JSON.stringify(
                {
                  'Span Kind': spanData.kind,
                  'Timestamp (UTC)': moment(spanData.timestamp / 1000)
                    .utc()
                    .format(OUTPUT_TIME_FORMAT),
                  [`Timestamp (${getLocalTimezoneAbbreviation()})`]: time,
                  'Time taken': spanData['time-taken-in-ms'] + ' ms',
                  'Status Code': spanData['status-code'],
                },
                null,
                2
              );
              const traceMetadata = JSON.stringify(metadata, null, 2);
              return {
                adserver,
                reqUri,
                reqBody,
                reqHeaders,
                respBody,
                respHeaders,
                respStatus,
                payloadType,
                spanMetadata,
                traceMetadata,
                time,
              };
            }
          );

          traceData.push({
            trace_id: index,
            deviceName: metadata?.device?.name ?? NA,
            integration: metadata?.['ad-request']?.integration ?? NA,
            contentPartnerIdentifier: metadata?.content['content-partner-identifier'] ?? NA,
            seriesIdentifier: metadata?.content['series-identifier'] ?? NA,
            contentTitle: metadata?.content?.title ?? NA,
            contentId: metadata?.content?.id ?? NA,
            contentDistributor: metadata?.content?.['distributor'] ?? NA,
            adsSpans,
            deviceId: metadata?.device?.id ?? NA,
            distributorPlatform: metadata?.device['distributor-platform'] ?? NA,
            deviceDistributor: metadata?.device['distributor-name'] ?? NA,
            pod: metadata?.['ad-request']?.['pod-number'] ?? NA,
            time: requestEntryTime ?? NA,
            status: requestEntryStatus ?? NA,
            isVppa: metadata?.user['vppa-consent'] ?? NA,
            isOptOut: metadata?.device['opt-out'] ?? NA,
            isCoppa: (metadata?.content?.coppa || metadata?.user?.coppa) ?? NA,
            country: metadata?.geo?.country ?? NA,
            adServer: metadata?.['ad-request']?.['ad-server'] ?? NA,
            publisher: metadata?.['ad-request']?.publisher ?? NA,
          });
        }
        yield put({ type: 'updateTraces', payload: traceData });
      }
    },
    // Update Test Case Statuses
    *updateTestCaseStatus({ payload: testCaseId }, { call, put }) {
      (yield call(updateTestCase, testCaseId)) &&
        (yield put({ type: 'fetchTestCases' })) &&
        (yield call(notification.success, {
          message: `Updating Test Case ${testCaseId} succeeded.`,
          duration: 3,
        }));
    },
    *deleteTestCase({ payload: testCaseId }, { call, put }) {
      (yield call(deleteTestCase, testCaseId)) &&
        (yield call(notification.success, {
          message: `Deleting Test Case ${testCaseId} succeeded.`,
          duration: 3,
        }));
      yield put(routerRedux.push('/' + ADS_TRACER));
    },
    *deleteSharedTestCase({ payload: testCaseId }, { call, put }) {
      (yield call(deleteSharedTestCase, testCaseId)) &&
        (yield put({ type: 'fetchTestCases' })) &&
        (yield call(notification.success, {
          message: `Deleting Shared Test Case ${testCaseId} succeeded.`,
          duration: 3,
        }));
    },
    // Make Test Case Shareable
    *shareTestCase({ payload: testCaseId }, { call, put, select }) {
      (yield call(shareTestCase, testCaseId)) &&
        (yield put({ type: 'pageInit', payload: yield select(pathParamsSelector) })) &&
        (yield call(notification.success, {
          message: `Sharing Test Case: ${testCaseId} succeeded.`,
          duration: 3,
        }));
    },
    // Accept Shared Test Case by idss
    *acceptSharedTestCase({ payload: testCaseId }, { call, put, select }) {
      (yield call(acceptSharedTestCase, testCaseId)) &&
        (yield put({ type: 'pageInit', payload: yield select(pathParamsSelector) })) &&
        (yield call(notification.success, {
          message: `Accepting Shared Test Case: ${testCaseId} succeeded.`,
          duration: 3,
        }));
    },
  },
};
