import { useMemo } from 'react';
import { VDMS, DSS, CAS } from '../constants';

const FIELDS_NAMES = {
  ID: 'Content ID',
  ASSET_ID: 'Content Alid ID',
  PARTNER: 'Content Partner',
  GENRE: 'Content Genre',
  LEN: 'Content Len',
  LIVE_STREAM: 'Content LiveStream',
  EXT_CHANNEL: 'Content EXT Channel',
  SERIES: 'Content Series Name',
  DISTRIBUTOR: 'Distributor',
};

const DEFAULT_SUPPLY_SOURCE_CONTENT_DETAILS = [
  { label: FIELDS_NAMES.ID, value: '-' },
  { label: FIELDS_NAMES.ASSET_ID, value: '-' },
  { label: FIELDS_NAMES.PARTNER, value: '-' },
  { label: FIELDS_NAMES.GENRE, value: '-' },
  { label: FIELDS_NAMES.LEN, value: '-' },
  { label: FIELDS_NAMES.LIVE_STREAM, value: '-' },
  { label: FIELDS_NAMES.EXT_CHANNEL, value: '-' },
  { label: FIELDS_NAMES.SERIES, value: '-' },
  { label: FIELDS_NAMES.DISTRIBUTOR, value: '-' },
];

const vdmsSupplySourceContentDetails = (data) => [
  { label: FIELDS_NAMES.ID, value: data?.content?.id },
  { label: FIELDS_NAMES.GENRE, value: data?.content?.genre },
  { label: FIELDS_NAMES.LEN, value: data?.content?.len },
  { label: FIELDS_NAMES.LIVE_STREAM, value: data?.content?.livestream },
  { label: FIELDS_NAMES.EXT_CHANNEL, value: data?.content?.ext?.channel },
];

const dssSupplySourceContentDetails = (data) => [
  { label: FIELDS_NAMES.ID, value: data?.app?.content?.id },
  { label: FIELDS_NAMES.GENRE, value: data?.app?.content?.genre },
  { label: FIELDS_NAMES.LEN, value: data?.app?.content?.len },
  { label: FIELDS_NAMES.LIVE_STREAM, value: data?.app?.content?.livestream },
  { label: FIELDS_NAMES.EXT_CHANNEL, value: data?.app?.content?.ext?.channel },
];

const casSupplySourceContentDetails = (data) => {
  const contentDataV2 = data?.['content-info'];
  const contentPartnerIdentifierV2 =
    contentDataV2?.['content-partner-id'] && contentDataV2?.['content-partner-identifier']
      ? `${contentDataV2?.['content-partner-id']} ${contentDataV2?.['content-partner-identifier']}`
      : null;

  const contentDataV3 = data?.content;
  const contentPartnerIdentifierV3 = contentDataV3?.['content-partner']?.['content-partner-id'];
  return [
    { label: FIELDS_NAMES.ASSET_ID, value: contentDataV3?.alid },
    { label: FIELDS_NAMES.PARTNER, value: contentPartnerIdentifierV2 || contentPartnerIdentifierV3 },
    { label: FIELDS_NAMES.GENRE, value: contentDataV2?.genres || contentDataV3?.genres },
    { label: 'Content Playback Type', value: contentDataV2?.vdm || contentDataV3?.['playback-type'] },
    { label: FIELDS_NAMES.EXT_CHANNEL, value: contentDataV2?.channel },
    { label: FIELDS_NAMES.SERIES, value: contentDataV2?.series || contentDataV3?.['series-name'] },
    { label: FIELDS_NAMES.DISTRIBUTOR, value: contentDataV2?.distributor || contentDataV3?.distributor },
  ];
};

const supplySourceMap = {
  [VDMS]: vdmsSupplySourceContentDetails,
  [DSS]: dssSupplySourceContentDetails,
  [CAS]: casSupplySourceContentDetails,
};

const resolveSupplySourceContentDetails = (type, data) => {
  const fn = supplySourceMap[type];
  return fn ? fn(data) : DEFAULT_SUPPLY_SOURCE_CONTENT_DETAILS;
};

export const useSupplySourceContentDetailsResolver = (type, data) => {
  return useMemo(() => resolveSupplySourceContentDetails(type, data), [type, data]);
};
