import 'react-html5video/dist/styles.css';
import { Col, Row, Card, Statistic, Button, Modal } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';
import ReactPlayer from 'react-player';

import { ACCESS, VIEW_ASSET_VIDEOS } from '../../../constants';
import {
  CREATIVETRACKER_TABLE_ASSET_COMMENT,
  CREATIVETRACKER_TABLE_ASSET_MODAL,
  CREATIVETRACKER_TABLE_ASSET_PREVIEW_BUTTON,
  CREATIVETRACKER_TABLE_ASSET_REASON,
  PERMISSION_PLACEHOLDER,
} from '../../../testUtils';
import { connect } from 'dva';
import {
  ASSET_ID_KEY,
  ASSET_NAME_KEY,
  CREATIVE_TYPE_KEY,
  MEDIA_FILE_URL_KEY,
  REJECTION_REASONS_KEY,
  COMMENT_KEY,
} from '../constants';

const STATISTIC_FONT = { fontSize: '12px' };

const AssetDetailBox = ({ asset = {}, creative = {}, permissions = PERMISSION_PLACEHOLDER }) => {
  // Variables to pass into components
  const statistics = [
    { name: 'Asset ID', value: creative?.[ASSET_ID_KEY] ?? 'N/A', span: 5 },
    { name: 'Asset Name', value: creative?.[ASSET_NAME_KEY] ?? 'N/A', span: 5 },
    { name: 'Type', value: creative?.[CREATIVE_TYPE_KEY] ?? 'N/A' },
    { name: 'Length', value: asset?.length ?? 'N/A' },
    { name: 'Height', value: asset?.height ?? 'N/A' },
    { name: 'Width', value: asset?.width ?? 'N/A' },
  ].map(({ name, value, span = 2 }) => (
    <Col style={{ overflow: 'hidden' }} span={span}>
      <Statistic title={name} value={value} valueStyle={STATISTIC_FONT} groupSeparator={''} />
    </Col>
  ));

  const [setModalOpen, setSetModalOpen] = useState(false);
  const previewButtonOnClick = () => setSetModalOpen(true);
  const modalClose = () => {
    setSetModalOpen(false);
  };

  const previewButton =
    // Determine if there's even an asset to preview, to proceed preview rendering
    creative[MEDIA_FILE_URL_KEY] ? (
      <div data-testid={CREATIVETRACKER_TABLE_ASSET_PREVIEW_BUTTON}>
        <Col>
          <Button
            type="primary"
            onClick={previewButtonOnClick}
            disabled={permissions.cannot(ACCESS, VIEW_ASSET_VIDEOS)}
          >
            <b>Preview </b>
            <PlayCircleOutlined />
          </Button>
        </Col>
      </div>
    ) : (
      <></>
    );

  const comment =
    creative[COMMENT_KEY] && creative[COMMENT_KEY].length > 0 ? (
      <div data-testid={CREATIVETRACKER_TABLE_ASSET_COMMENT}>
        <Row>
          <span>
            <b> Comment: </b>
            {creative[COMMENT_KEY]}
          </span>
        </Row>
      </div>
    ) : (
      <></>
    );

  const reason =
    creative[REJECTION_REASONS_KEY] && creative[REJECTION_REASONS_KEY].length > 0 ? (
      <div data-testid={CREATIVETRACKER_TABLE_ASSET_REASON}>
        <Row>
          <span>
            <b> Rejection Reasons: </b>
            {creative[REJECTION_REASONS_KEY].join(' , ')}
          </span>
        </Row>
      </div>
    ) : (
      <></>
    );

  const modal = (
    <Modal
      title={`Video Preview for Asset Id: ${asset[ASSET_ID_KEY]}`}
      footer={null}
      closable
      open={setModalOpen}
      onCancel={modalClose}
      width="70%"
    >
      <div data-testid={CREATIVETRACKER_TABLE_ASSET_MODAL}>
        <ReactPlayer controls={true} url={creative[MEDIA_FILE_URL_KEY]} />
      </div>
    </Modal>
  );

  return (
    <>
      <Card>
        <Row>
          {statistics}
          {previewButton}
        </Row>
        <Row>{reason}</Row>
        <Row>{comment}</Row>
      </Card>
      {modal}
    </>
  );
};

function mapStateToProps({ app }) {
  return { permissions: app.permissions };
}

export default connect(mapStateToProps)(AssetDetailBox);
