import { Typography } from '@hulu/react-style-components';
import { Button, Form, Select, Space } from 'antd';
import { connect } from 'dva';
import { useEffect, useState } from 'react';

import { SearchFilterWrapper, StyledButton } from './styles';
import { DATA_TYPE, EMPTY_VALUES, SELECT_MODE, VIEW } from '../../../constants';
import { MomentDatePicker } from '../../../utils';

import {
  CREATIVETRACKER_SEARCH,
  CREATIVETRACKER_SEARCH_BUTTONS,
  CREATIVETRACKER_SEARCH_FORM,
} from '../../../testUtils';
import { INITIAL_FILTER_STATE, INITIAL_SELECTED_VALUES } from '../../../models/creativeTracker';
import moment from 'moment-timezone';
import { FILTER_FIELDS_MAP } from '../constants';

const { RangePicker } = MomentDatePicker;

const SearchFilter = ({ dispatch, filterData = INITIAL_FILTER_STATE, permissions }) => {
  const [form] = Form.useForm();
  const [isShowAllFilters, setShowAllFilters] = useState(false);
  const toggleShowAllFilters = () => setShowAllFilters(!isShowAllFilters);

  useEffect(() => {
    return () => {
      // reset fields during unmounting
      resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values) => {
    dispatch({ type: 'creativeTracker/updateFilterSelected', payload: values });
    dispatch({ type: 'creativeTracker/pageUpdate' });
  };

  // We convert the selected local date time in UI to UTC time before sending to backend.
  const datePicker = (field, selected) => (
    <RangePicker
      allowClear={true}
      defaultValue={selected}
      showTime={{ format: 'HH:mm:ss' }} // Enables time picker with seconds
      onChange={(dates) => {
        form.setFieldsValue({
          [field]: dates
            ? [
                moment(dates[0])
                  .utc() // Convert the selected start date to UTC
                  .format('YYYY-MM-DD[T]HH:mm:ss[Z]'), // Include UTC timezone (Z)
                moment(dates[1])
                  .utc() // Convert the selected end date to UTC
                  .format('YYYY-MM-DD[T]HH:mm:ss[Z]'), // Include UTC timezone (Z)
              ]
            : [],
        });
      }}
      format="MM/DD/YYYY HH:mm:ss" // Display format (local timezone)
    />
  );

  const multiSelect = (field, selectMode = SELECT_MODE.TAGS, selected, options, maxCount, dataType, name) => (
    <Select
      allowClear
      maxCount={maxCount}
      mode={selectMode}
      notFoundContent={EMPTY_VALUES}
      tokenSeparators={[',']}
      placeholder={name}
      defaultValue={selected}
      onChange={(vals) =>
        dataType === DATA_TYPE.NUMBER
          ? form.setFieldsValue({ [field]: vals.map((val) => Number(val)) })
          : form.setFieldsValue({ [field]: vals })
      }
      options={options}
      optionFilterProp={'label'}
    />
  );

  const fillout = (field, { name, selectMode, selected, options, 'max-count': maxCount, dataType, rules = [] }) => {
    return (
      <Form.Item key={field} name={field} rules={rules}>
        <>
          <Typography variant="h8" style={{ marginRight: '3px' }}>
            {name}
          </Typography>
          {field.toLowerCase().endsWith('range')
            ? datePicker(field, selected)
            : multiSelect(field, selectMode, selected, options, maxCount, dataType, name)}
        </>
      </Form.Item>
    );
  };

  // Create Fillouts
  let fillouts = [];
  const filterDataAsArray = Object.entries(filterData).filter(([field]) =>
    permissions.can(VIEW, FILTER_FIELDS_MAP[field])
  );
  if (isShowAllFilters) {
    fillouts = filterDataAsArray.map(([field, data]) => fillout(field, data));
  } else {
    for (let i = 0; i < Math.min(6, filterDataAsArray.length); i++) {
      fillouts.push(fillout(filterDataAsArray[i][0], filterDataAsArray[i][1]));
    }
  }

  const resetFields = () => {
    dispatch({ type: 'creativeTracker/resetFields' });
    form.resetFields();
  };

  return (
    <SearchFilterWrapper data-testid={CREATIVETRACKER_SEARCH}>
      <Typography variant="h4">Filter By Field</Typography>
      <br />
      <Form form={form} onFinish={onFinish} initialValues={INITIAL_SELECTED_VALUES}>
        {/* Fillout Sections */}
        <div data-testid={CREATIVETRACKER_SEARCH_FORM}>{fillouts}</div>

        {/* Fillout Buttons */}
        <div data-testid={CREATIVETRACKER_SEARCH_BUTTONS}>
          {filterDataAsArray.length > 6 && (
            <StyledButton size="small" onClick={toggleShowAllFilters}>
              {`Show ${isShowAllFilters ? 'less' : 'more'} filters`}
            </StyledButton>
          )}
          <br />
          <Form.Item>
            <Space>
              <Button htmlType="button" onClick={resetFields}>
                Reset
              </Button>
              <Button key="submit" type="primary" htmlType="submit">
                Submit
              </Button>
            </Space>
          </Form.Item>
        </div>
      </Form>
    </SearchFilterWrapper>
  );
};

function mapStateToProps({ app, creativeTracker }) {
  return {
    filterData: creativeTracker.filters,
    permissions: app.currentUser.permissions,
  };
}

export default connect(mapStateToProps)(SearchFilter);
