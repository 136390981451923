// Common String Test Id Constants

export const HEADER = 'header';
export const MODAL = 'modal';
export const BUTTON = 'button';
export const TABLE = 'table';
export const STEPS = 'steps';
export const TRANSFER = 'transfer';
export const CANCEL = 'cancel';
export const SUBMIT = 'submit';
export const INGEST = 'ingest';
export const COPY = 'copy';
export const SHARE = 'share';
export const REMOVE = 'remove';
export const SELECT = 'select';
export const FORM = 'form';
export const EDIT = 'edit';
export const DATEPICKER = 'datepicker';
export const TOGGLE = 'toggle';
export const LIST = 'list';
export const TOOLTIP = 'tooltip';
export const TAB = 'tab';
export const TABNAV = 'tabnav';
export const BACK = 'back';
export const SEARCH = 'search';
export const BARCHART = 'barchart';
export const TITLE = 'title';

// nav header
export const NAV = 'nav';
export const NAV_HEADER = [NAV, HEADER].join('_');
export const NAV_SKIP = [NAV, 'skip'].join('_');

// Main Ads Tracer data test ids

export const ADSTRACER = 'adstracer';
export const LOADTEST = 'loadtest';
export const SHARETEST = 'sharetest';
export const TRACESPAN = 'tracespan';
export const TESTCASES = 'testcases';
export const TRACESUMMARIES = 'tracesummaries';

export const ADSTRACER_HEADER = [ADSTRACER, HEADER].join('_');
export const ADSTRACER_TESTCASES = [ADSTRACER, TESTCASES].join('_');
export const ADSTRACER_TRACESUMMARIES = [ADSTRACER, TRACESUMMARIES].join('_');
export const ADSTRACER_TESTCASES_TABLE = [ADSTRACER_TESTCASES, TABLE].join('_');
export const ADSTRACER_TRACESUMMARIES_TABLE = [ADSTRACER_TRACESUMMARIES, TABLE].join('_');
export const ADSTRACER_HEADER_NEWTEST = [ADSTRACER_HEADER, 'newtest'].join('_');
export const ADSTRACER_HEADER_SHAREDTEST = [ADSTRACER_TESTCASES, 'sharedtest'].join('_');
export const ADSTRACER_HEADER_TOGGLEREFRESH = [ADSTRACER_HEADER, 'togglerefresh'].join('_');
export const ADSTRACER_HEADER_REFRESH = [ADSTRACER_HEADER, 'refresh'].join('_');
export const ADSTRACER_MODAL_LOADTEST = [ADSTRACER, MODAL, LOADTEST].join('_');
export const ADSTRACER_MODAL_LOADTEST_BUTTON_CANCEL = [ADSTRACER, MODAL, LOADTEST, BUTTON, CANCEL].join('_');
export const ADSTRACER_MODAL_LOADTEST_BUTTON_SUBMIT = [ADSTRACER, MODAL, LOADTEST, BUTTON, SUBMIT].join('_');
export const ADSTRACER_MODAL_SHARETEST = [ADSTRACER, MODAL, SHARETEST].join('_');
export const ADSTRACER_MODAL_SHARETEST_BUTTON_CANCEL = [ADSTRACER, MODAL, SHARETEST, BUTTON, CANCEL].join('_');
export const ADSTRACER_MODAL_SHARETEST_BUTTON_COPY = [ADSTRACER, MODAL, SHARETEST, BUTTON, COPY].join('_');
export const ADSTRACER_MODAL_SHARETEST_BUTTON_SHARE = [ADSTRACER, MODAL, SHARETEST, BUTTON, SHARE].join('_');
export const ADSTRACER_MODAL_TRACESPAN = [ADSTRACER, MODAL, TRACESPAN].join('_');

// New Test case view of Ads Tracer data test ids

export const NEWTESTCASE = 'newtestcase';
export const CONTENT = 'content';
export const ACTION = 'action';

export const NEWTESTCASE_STEPS = [NEWTESTCASE, STEPS].join('_');
export const NEWTESTCASE_STEPS_CONTENT = [NEWTESTCASE_STEPS, CONTENT].join('_');
export const NEWTESTCASE_STEPS_ACTION = [NEWTESTCASE_STEPS, ACTION].join('_');
export const NEWTESTCASE_MODAL = [NEWTESTCASE, MODAL].join('_');
export const NEWTESTCASE_TRANSFER = [NEWTESTCASE, TRANSFER].join('_');
export const NEWTESTCASE_DATEPICKER = [NEWTESTCASE, 'datepicker'].join('_');
export const NEWTESTCASE_REVIEW = [NEWTESTCASE, 'review'].join('_');
export const NEWTESTCASE_DISNEY = [NEWTESTCASE, 'disney'].join('_');
export const NEWTESTCASE_HULU = [NEWTESTCASE, 'hulu'].join('_');

// Ad Investigator data test ids

export const ADINVESTIGATOR = 'adinvestigator';
export const USERPROFILE = 'userprofile';
export const ACCOUNTPROFILE = 'accountprofile';
export const DEVICE = 'device';
export const ADS = 'ads';

export const ADINVESTIGATOR_CREATE_RULES = [ADINVESTIGATOR, 'create', 'rules'].join('_');
export const ADINVESTIGATOR_VIEW_RULES = [ADINVESTIGATOR, 'view', 'rules'].join('_');
export const ADINVESTIGATOR_VIEW_ADS = [ADINVESTIGATOR, 'view', ADS].join('_');
export const ADINVESTIGATOR_CREATE_RULES_DATEPICKER = [ADINVESTIGATOR_CREATE_RULES, DATEPICKER].join('_');
export const ADINVESTIGATOR_CREATE_RULES_SELECT = [ADINVESTIGATOR_CREATE_RULES, SELECT].join('_');
export const ADINVESTIGATOR_CREATE_RULES_POD_SLOT = [ADINVESTIGATOR_CREATE_RULES, 'podslot'].join('_');
export const ADINVESTIGATOR_CREATE_RULES_ADS = [ADINVESTIGATOR_CREATE_RULES, 'ads'].join('_');
export const ADINVESTIGATOR_CREATE_RULES_ADD = [ADINVESTIGATOR_CREATE_RULES, 'add'].join('_');
export const ADINVESTIGATOR_CREATE_RULES_REMOVE = [ADINVESTIGATOR_CREATE_RULES, REMOVE].join('_');
export const ADINVESTIGATOR_CREATE_RULES_FORM = [ADINVESTIGATOR_CREATE_RULES, FORM].join('_');
export const ADINVESTIGATOR_CREATE_RULES_USERPROFILE = [ADINVESTIGATOR_CREATE_RULES, USERPROFILE].join('_');
export const ADINVESTIGATOR_CREATE_RULES_ACCOUNTPROFILE = [ADINVESTIGATOR_CREATE_RULES, ACCOUNTPROFILE].join('_');
export const ADINVESTIGATOR_CREATE_RULES_DEVICE = [ADINVESTIGATOR_CREATE_RULES, 'device'].join('_');
export const ADINVESTIGATOR_CREATE_RULES_TOGGLE = [ADINVESTIGATOR_CREATE_RULES, TOGGLE].join('_');
export const ADINVESTIGATOR_CREATE_RULES_ADTYPE_TOGGLE = [ADINVESTIGATOR_CREATE_RULES, 'adtype', TOGGLE].join('_');
export const ADINVESTIGATOR_CREATE_RULES_SUBMIT = [ADINVESTIGATOR_CREATE_RULES, SUBMIT].join('_');
export const ADINVESTIGATOR_CREATE_RULES_LIST = [ADINVESTIGATOR_CREATE_RULES, LIST].join('_');
export const ADINVESTIGATOR_CREATE_RULES_MODAL = [ADINVESTIGATOR_CREATE_RULES, MODAL].join('_');
export const ADINVESTIGATOR_CREATE_RULES_USERDATA_LIST = [ADINVESTIGATOR_CREATE_RULES, 'userdata', LIST].join('_');
export const ADINVESTIGATOR_VIEW_RULES_TABLE = [ADINVESTIGATOR_VIEW_RULES, TABLE].join('_');
export const ADINVESTIGATOR_VIEW_RULES_TABLE_REMOVE_TOOLTIP = [ADINVESTIGATOR_VIEW_RULES, REMOVE, TOOLTIP].join('_');
export const ADINVESTIGATOR_VIEW_RULES_TABLE_EDIT_TOOLTIP = [ADINVESTIGATOR_VIEW_RULES, EDIT, TOOLTIP].join('_');
export const ADINVESTIGATOR_VIEW_RULES_SELECT_OVERRIDEBY = [ADINVESTIGATOR_VIEW_RULES, SELECT, 'overrideby'].join('_');
export const ADINVESTIGATOR_VIEW_RULES_SUBMIT = [ADINVESTIGATOR_VIEW_RULES, SUBMIT].join('_');
export const ADINVESTIGATOR_VIEW_RULES_USERPROFILE = [ADINVESTIGATOR_VIEW_RULES, USERPROFILE].join('_');
export const ADINVESTIGATOR_VIEW_RULES_DEVICE = [ADINVESTIGATOR_VIEW_RULES, DEVICE].join('_');
export const ADINVESTIGATOR_VIEW_RULES_MODAL = [ADINVESTIGATOR_VIEW_RULES, MODAL].join('_');

export const DURATION_MANAGER = 'durationmanager';
export const RULEINFO = 'ruleinfo';

export const DURATION_MANAGER_BUTTONS = [DURATION_MANAGER, BUTTON].join('_');
export const DURATION_MANAGER_TABLE = [DURATION_MANAGER, TABLE].join('_');
export const DURATION_MANAGER_RULEINFO = [DURATION_MANAGER, RULEINFO].join('_');
export const DURATION_MANAGER_RULEINFO_BUTTON = [DURATION_MANAGER_RULEINFO, BUTTON].join('_');
export const DURATION_MANAGER_RULEINFO_CONTENT = [DURATION_MANAGER_RULEINFO, 'content'].join('_');
export const DURATION_MANAGER_MODAL = [DURATION_MANAGER, MODAL].join('_');
export const DURATION_MANAGER_RULEEDIT = [DURATION_MANAGER, EDIT].join('_');
export const DURATION_MANAGER_RULEEDIT_ADD = [DURATION_MANAGER_RULEEDIT, 'add'].join('_');
export const DURATION_MANAGER_CREATE = [DURATION_MANAGER, 'create'].join('_');
export const DURATION_MANAGER_CREATE_BACK = [DURATION_MANAGER_CREATE, BACK].join('_');
export const DURATION_MANAGER_CREATE_RULETYPE = [DURATION_MANAGER_CREATE, 'ruletype'].join('_');
export const DURATION_MANAGER_CREATE_CHANNELID = [DURATION_MANAGER_CREATE, 'channelid'].join('_');
export const DURATION_MANAGER_CREATE_SERIESID = [DURATION_MANAGER_CREATE, 'seriesid'].join('_');
export const DURATION_MANAGER_CREATE_LEAGUEID = [DURATION_MANAGER_CREATE, 'leagueid'].join('_');
export const DURATION_MANAGER_CREATE_RULEINFO = [DURATION_MANAGER_CREATE, RULEINFO].join('_');
export const DURATION_MANAGER_CREATE_STREAMTYPE = [DURATION_MANAGER_CREATE, 'streamtype'].join('_');
export const DURATION_MANAGER_BULK = [DURATION_MANAGER, 'bulk'].join('_');
export const DURATION_MANAGER_BULK_BACK = [DURATION_MANAGER_BULK, BACK].join('_');
export const DURATION_MANAGER_BULK_HEADER = [DURATION_MANAGER_BULK, HEADER].join('_');
export const DURATION_MANAGER_BULK_TABLE = [DURATION_MANAGER_BULK, TABLE].join('_');

// Creative Tracker data test ids

export const CREATIVETRACKER = 'creativetracker';
export const ASSET = 'asset';

export const CREATIVETRACKER_SEARCH = [CREATIVETRACKER, SEARCH].join('_');
export const CREATIVETRACKER_SEARCH_BUTTONS = [CREATIVETRACKER_SEARCH, BUTTON].join('_');
export const CREATIVETRACKER_SEARCH_FORM = [CREATIVETRACKER_SEARCH, FORM].join('_');
export const CREATIVETRACKER_TABLE = [CREATIVETRACKER, TABLE].join('_');
export const CREATIVETRACKER_TABLE_STEPS = [CREATIVETRACKER_TABLE, STEPS].join('_');
export const CREATIVETRACKER_TABLE_ASSET = [CREATIVETRACKER_TABLE, ASSET].join('_');
export const CREATIVETRACKER_TABLE_ROW_LOADING = [CREATIVETRACKER_TABLE, 'rowloading'].join('_');
export const CREATIVETRACKER_TABLE_ASSET_PREVIEW_BUTTON = [CREATIVETRACKER_TABLE_ASSET, 'preview', BUTTON].join('_');
export const CREATIVETRACKER_TABLE_ASSET_REASON = [CREATIVETRACKER_TABLE_ASSET, 'reason'].join('_');
export const CREATIVETRACKER_TABLE_ASSET_COMMENT = [CREATIVETRACKER_TABLE_ASSET, 'comment'].join('_');
export const CREATIVETRACKER_TABLE_ASSET_MODAL = [CREATIVETRACKER_TABLE_ASSET, MODAL].join('_');

// Vast Tracker data test ids

export const VASTTRACKER = 'vasttracker';

export const VASTTRACKER_BARCHART = [VASTTRACKER, BARCHART].join('_');
export const VASTTRACKER_BARCHART_TOPNUMSUFFIX = [VASTTRACKER, BARCHART, 'topnum'].join('_');
export const VASTTRACKER_BARCHART_TITLE = [VASTTRACKER, BARCHART, TITLE].join('_');
export const VASTTRACKER_TABLE = [VASTTRACKER, TABLE].join('_');
export const VASTTRACKER_SEARCH = [VASTTRACKER, SEARCH].join('_');
export const VASTTRACKER_SEARCH_TITLE = [VASTTRACKER_SEARCH, TITLE].join('_');
export const VASTTRACKER_SEARCH_ID = [VASTTRACKER_SEARCH, 'id'].join('_');
export const VASTTRACKER_SEARCH_ID_BUTTON = [VASTTRACKER_SEARCH_ID, BUTTON].join('_');
export const VASTTRACKER_SEARCH_DATE = [VASTTRACKER_SEARCH, 'date'].join('_');
export const VASTTRACKER_HEADER = [VASTTRACKER, HEADER].join('_');
export const VASTTRACKER_HEADER_TITLE = [VASTTRACKER_HEADER, TITLE].join('_');
export const VASTTRACKER_HEADER_NAV = [VASTTRACKER_HEADER, 'nav'].join('_');

// Reservation Tool data test ids

export const RESERVATION_TOOL = 'reservationtool';
export const RESERVE_SERIES = 'reserveseries';
export const VIEW_AUDIT_TRAIL = 'viewaudittrail';
export const FIRST_STEP = 'firststep';
export const SECOND_STEP = 'secondstep';
export const THIRD_STEP = 'thirdstep';

export const RESERVATION_TOOL_TITLE = [RESERVATION_TOOL, TITLE].join('_');
export const RESERVATION_TOOL_TABNAV = [RESERVATION_TOOL, TABNAV].join('_');
export const RESERVATION_TOOL_TAB_RESERVE_SERIES = [RESERVATION_TOOL, TAB, RESERVE_SERIES].join('_');
export const RESERVATION_TOOL_RESERVE_SERIES_FIRST_STEP = [RESERVATION_TOOL, RESERVE_SERIES, FIRST_STEP].join('_');
export const RESERVATION_TOOL_RESERVE_SERIES_FIRST_STEP_SELECT = [
  RESERVATION_TOOL,
  RESERVE_SERIES,
  FIRST_STEP,
  SELECT,
].join('_');
export const RESERVATION_TOOL_RESERVE_SERIES_SECOND_STEP = [RESERVATION_TOOL, RESERVE_SERIES, SECOND_STEP].join('_');
export const RESERVATION_TOOL_RESERVE_SERIES_SECOND_STEP_PICKER = [
  RESERVATION_TOOL,
  RESERVE_SERIES,
  SECOND_STEP,
  SELECT,
].join('_');
export const RESERVATION_TOOL_RESERVE_SERIES_THIRD_STEP = [RESERVATION_TOOL, RESERVE_SERIES, THIRD_STEP].join('_');
export const RESERVATION_TOOL_RESERVE_SERIES_THIRD_STEP_PICKER = [
  RESERVATION_TOOL,
  RESERVE_SERIES,
  THIRD_STEP,
  SELECT,
].join('_');
export const RESERVATION_TOOL_TAB_VIEW_AUDIT_TRAIL = [RESERVATION_TOOL, TAB, VIEW_AUDIT_TRAIL].join('_');

export const PERMISSION_PLACEHOLDER = { can: () => true, cannot: () => false };
export const TEST_PERMISSIONS_FALSE = {
  can: () => false,
  cannot: () => false,
};
export const TEST_PERMISSIONS_TRUE = {
  can: () => true,
  cannot: () => true,
};

// Q-Check data test ids

export const Q_CHECK = 'qcheck';
export const MEDIA_FILE_URL = 'mediafileurl';
export const VAST_XML = 'vastxml';
export const VAST_URL = 'vasturl';
export const ERROR = 'error';

export const Q_CHECK_TITLE = [Q_CHECK, TITLE].join('_');
export const Q_CHECK_TABNAV = [Q_CHECK, TABNAV].join('_');
export const Q_CHECK_TAB_MEDIA_FILE_URL = [Q_CHECK, TAB, MEDIA_FILE_URL].join('_');
export const Q_CHECK_TAB_VAST_XML = [Q_CHECK, TAB, VAST_XML].join('_');
export const Q_CHECK_MEDIA_FILE_URL_SUBMIT = [Q_CHECK, MEDIA_FILE_URL, SUBMIT].join('_');
export const Q_CHECK_MEDIA_FILE_URL_FORM = [Q_CHECK, MEDIA_FILE_URL, FORM].join('_');
export const Q_CHECK_VAST_XML_SUBMIT = [Q_CHECK, VAST_XML, SUBMIT].join('_');
export const Q_CHECK_VAST_XML_INGEST = [Q_CHECK, VAST_XML, INGEST].join('_');
export const Q_CHECK_VAST_XML_FORM = [Q_CHECK, VAST_XML, FORM].join('_');
export const Q_CHECK_VAST_URL_BUTTON = [Q_CHECK, VAST_URL, BUTTON].join('_');
export const Q_CHECK_VAST_XML_BUTTON = [Q_CHECK, VAST_XML, BUTTON].join('_');
export const Q_CHECK_VAST_XML_URL_PLACEHOLDER = [Q_CHECK, VAST_URL, 'placeholder'].join('_');
export const Q_CHECK_VAST_XML_TEXTAREA = [Q_CHECK, VAST_XML, 'textarea'].join('_');
export const Q_CHECK_ERROR = [Q_CHECK, ERROR].join('_');

// Auction Snapshot data test ids

export const AUCTION_SNAPSHOT = 'auctionsnapshot';

export const AUCTION_SNAPSHOT_HEADING = [AUCTION_SNAPSHOT, TITLE].join('_');
export const AUCTION_SNAPSHOT_TABLE = [AUCTION_SNAPSHOT, TABLE].join('_');
export const AUCTION_SNAPSHOT_TABLE_ROW_SELECT = [AUCTION_SNAPSHOT_TABLE, 'row', SELECT].join('_');
export const AUCTION_SNAPSHOT_TABLE_DETAIL = [AUCTION_SNAPSHOT_TABLE, 'detail'].join('_');
