import { useState, useEffect, useCallback } from 'react';
import { Form } from 'antd';
import _omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import intersection from 'lodash/intersection';

import { openNotificationWithIcon } from '../notifications';
import { getProfiles, createSnapshot } from '../../../services/auctionSnapshot';
import {
  FORM_FIELDS,
  AUCTION_PAYLOAD_NAMES_TO_REMOVE,
  USER_PARAMETERS_KEY,
  APP_PARAMETERS_KEY,
  CONTENT_PARAMETERS_KEY,
  DEMAND_PARAMETERS_KEY,
  PARAMETERS_BY_FIELD_NAME,
  WINNING_AUCTIONS,
  INVENTORY_TYPE_MAP,
  PARAMETER_NAMES,
  APP_INVENTORY_TYPE,
  WEB_INVENTORY_TYPE,
} from './constants';
import { MY_PROFILES_FILTER, OTHER_PROFILES_FILTER, SNAPSHOT_NAME_MAX_LENGTH } from '../constants';
import { usePrevious } from './usePrevious';

const { useWatch } = Form;

const formInitialValues = {
  userProfile: null,
  supplySource: null,
  advertiser: null,
  creativeId: null,
  result: 'all',
  inventoryType: 'All',
  appName: null,
  deviceFamily: null,
  ifaType: null,
  domain: null,
  userAgent: null,
  dnt: 'ALL',
  channel: null,
  series: null,
  assetId: null,
  contentPartner: null,
  vdm: null,
  sessionId: null,
  creativeDuration: [],
  mediaFileUrl: null,
  winningAuction: [],
  adPartnerDsp: null,
  receivedBidDealId: null,
  timeAllowedBeforeStopRunning: 'PT24H',
};

const isFieldNameEqualTo = (currentField, fieldName) => currentField === fieldName;

const createSnapshotNamePrefix = (parameters) => {
  const prefix = `${parameters.filter((x) => x).join('_')}`;
  return prefix.length ? `${prefix}_` : null;
};

const selectedSnapshotToValues = (selectedSnapshot, formInitialValues) => {
  const selectedValues = {
    ..._omit(selectedSnapshot, AUCTION_PAYLOAD_NAMES_TO_REMOVE),
    ...selectedSnapshot.supplyParameters,
    ...selectedSnapshot.demandParameters,
    snapshotName: `${selectedSnapshot.name} copy`,
  };
  const formattedSelectedValues = Object.entries(selectedValues).reduce((acc, curr) => {
    const [field, value] = curr;
    const isVDM = isFieldNameEqualTo(field, FORM_FIELDS.VDM.name);
    const isResult = isFieldNameEqualTo(field, FORM_FIELDS.RESULT.name);
    return { ...acc, ...{ [field]: isVDM || isResult ? value.toLowerCase() : value } };
  }, {});
  return {
    ...formInitialValues,
    ...formattedSelectedValues,
  };
};

const composePayloadToCreateSnapshot = (values) => {
  const { inputValue, selectType } = values?.selectionSearch ?? {};
  return {
    name: values.snapshotName?.trim(),
    timeAllowedBeforeStopRunning: values.timeAllowedBeforeStopRunning || formInitialValues.timeAllowedBeforeStopRunning,
    supplyParameters: {
      supplySource: values.supplySource,
      appName: values.appName,
      channel: values.channel,
      deviceFamily: values.deviceFamily,
      inventoryType: values.inventoryType,
      ifaType: values.ifaType,
      vdm: values.vdm,
      domain: values.domain,
      dnt: values.dnt,
      userAgent: values.userAgent,
      series: values.series,
      assetId: values.assetId,
      contentPartner: values.contentPartner,
      sessionId: values.sessionId,
      brand: values.brand,
      formFactor: values.formFactor,
      deviceCategory: values.deviceCategory,
      distributor: values.distributor,
      country: values.country,
      ...(inputValue && selectType && { selectionSearch: values.selectionSearch }),
    },
    demandParameters: {
      result: values.result,
      advertiser: values.advertiser,
      creativeDuration: values.creativeDuration,
      creativeId: values.creativeId,
      mediaFileUrl: values.mediaFileUrl,
      winningAuction: values.winningAuction,
      adPartnerDsp: values.adPartnerDsp,
      receivedBidDealId: values.receivedBidDealId,
      externalDealId: values.externalDealId,
      internalDealId: values.internalDealId,
      bidState: values.bidState,
    },
  };
};

const getSelectedFields = (selectedValues) => {
  return Object.entries(selectedValues).reduce((acc, curr) => {
    const [field, value] = curr;
    if (PARAMETER_NAMES[field]) {
      const isVDM = isFieldNameEqualTo(field, FORM_FIELDS.VDM.name);
      const obj = {
        selectedFieldName: field,
        value: isVDM ? value.toLowerCase() : value,
      };
      return [...acc, obj];
    }
    return acc;
  }, []);
};

const getParametersMap = (snapshotParameters) => {
  return Object.entries(snapshotParameters).reduce((allParameters, parameter) => {
    const [name, options] = parameter;
    const paramOptions = options.reduce((paramOptions, option) => {
      paramOptions[PARAMETERS_BY_FIELD_NAME[name]] = paramOptions[PARAMETERS_BY_FIELD_NAME[name]] || [];
      const currentFieldName = PARAMETERS_BY_FIELD_NAME?.[name]?.[0];
      if (currentFieldName) {
        if (currentFieldName === FORM_FIELDS.WINNING_AUCTION.name) {
          const optionToPush = option[PARAMETERS_BY_FIELD_NAME[name]][0].toLowerCase();
          paramOptions[PARAMETERS_BY_FIELD_NAME[name]].push(optionToPush);
        } else {
          paramOptions[PARAMETERS_BY_FIELD_NAME[name]].push(...option[PARAMETERS_BY_FIELD_NAME[name]]);
        }
      }
      return paramOptions;
    }, {});
    const parameterMap = options.reduce((map, obj) => {
      map[obj[PARAMETERS_BY_FIELD_NAME[name]]] = { ...obj, ...paramOptions };
      return map;
    }, {});
    allParameters[PARAMETERS_BY_FIELD_NAME[name]] = parameterMap;
    return allParameters;
  }, {});
};

const getParametersMapBySelectedParameter = (snapshotParameters, fieldName, value) => {
  return getParametersMap(snapshotParameters)[fieldName]?.[value] || {};
};

const getParametersMapBySelectedParameterArray = (snapshotParameters, selectedFieldName, values, fieldName) => {
  const paramsArr = values?.reduce((acc, currValue) => {
    const result = getParametersMapBySelectedParameter(
      snapshotParameters,
      selectedFieldName,
      WINNING_AUCTIONS[currValue]
    )[fieldName];
    return [...acc, result];
  }, []);
  return paramsArr;
};

const getOptionsFromSnapshotParams = (snapshotParameters, fieldName, selectedFileds = []) => {
  const selectedParametersMap = selectedFileds?.reduce((acc, curr) => {
    const { selectedFieldName, value } = curr;
    let result = [];
    if (Array.isArray(value) && !isEmpty(value)) {
      result = [
        ...acc,
        ...getParametersMapBySelectedParameterArray(snapshotParameters, selectedFieldName, value, fieldName),
      ];
    } else {
      result = [...acc, getParametersMapBySelectedParameter(snapshotParameters, selectedFieldName, value)[fieldName]];
    }
    return result;
  }, []);
  return intersection(...selectedParametersMap) || [];
};

const INITIAL_FORM_STATE = {
  selectedFileds: [],
  creativeDurationSelect: [],
  winnerAuctionSelect: [],
  inventoryTypeValue: 'All',
  createSnapshotFormActiveKeys: [USER_PARAMETERS_KEY],
  isInventoryTypeDisabled: false,
  isDeviceFamilyDisabled: false,
};

export const useAuctionSnapshotCreateForm = ({
  closeAdRequestDrawer,
  onSnapshotsRefresh,
  selectedSnapshot,
  setSelectedSnapshot,
  snapshotParameters,
}) => {
  const [formState, setFormState] = useState(INITIAL_FORM_STATE);
  const [profiles, setProfiles] = useState({ allUsers: [], currentUser: [] });
  const [isProfilesLoading, setIsProfilesLoading] = useState(false);
  const [namePrefix, setNamePrefix] = useState(null);
  const [namePrefixWarning, setNamePrefixWarning] = useState(false);
  const [, setFormValues] = useState({});
  const [form] = Form.useForm();

  const {
    creativeDurationSelect,
    winnerAuctionSelect,
    inventoryTypeValue,
    createSnapshotFormActiveKeys,
    selectedFileds,
    isInventoryTypeDisabled,
    isDeviceFamilyDisabled,
  } = formState;

  const selectedFieldValue = selectedFileds?.at(-1)?.value;
  const prevSelectedFieldValue = usePrevious(selectedFieldValue);

  const firstSelectedParameter = selectedFileds?.[0];
  const prevFirstSelectedParameter = usePrevious(firstSelectedParameter);

  const selectType = useWatch(FORM_FIELDS.SELECT_TYPE.name, form);
  const publisher = useWatch(FORM_FIELDS.APP_NAME.name, form);
  const country = useWatch(FORM_FIELDS.COUNTRY.name, form);
  const deviceId = useWatch(FORM_FIELDS.INPUT_VALUE.name, form);
  const isDspDisabled = !useWatch(FORM_FIELDS.WINNING_AUCTION.name, form)?.includes(WINNING_AUCTIONS.magnite);

  const fetchProfiles = useCallback(() => {
    setIsProfilesLoading(true);

    const myProfiles = getProfiles({ selectType, user: MY_PROFILES_FILTER });
    const otherProfiles = getProfiles({ selectType, user: OTHER_PROFILES_FILTER });
    Promise.all([myProfiles, otherProfiles])
      .then(([myProfilesData, otherProfilesData]) => {
        setProfiles({
          currentUser: myProfilesData?.content?.map((profile) => ({
            ...profile,
            value: profile.inputValue,
          })),
          allUsers: otherProfilesData?.content?.map((profile) => ({
            ...profile,
            value: profile.inputValue,
          })),
        });
      })
      .finally(() => setIsProfilesLoading(false));
  }, [selectType]);

  const handleSubmit = async (values) => {
    try {
      await createSnapshot(composePayloadToCreateSnapshot(values));
      await onSnapshotsRefresh();
      openNotificationWithIcon('success', `"${values.snapshotName}" snapshot`);
      closeForm();
    } catch (err) {
      console.error(err);
      openNotificationWithIcon('error', [err]);
    }
  };

  const onCreateSnapshotFormCollapseChange = (keys) => {
    setFormState((prevState) => ({
      ...prevState,
      createSnapshotFormActiveKeys: keys,
    }));
  };

  const handleSubmitFailed = ({ errorFields }) => {
    const errors = errorFields.reduce((acc, item) => [...acc, ...item.errors], []);
    openNotificationWithIcon('error', errors);
  };

  const closeForm = () => {
    form.resetFields();
    closeAdRequestDrawer();
    setSelectedSnapshot(null);
    setFormState(INITIAL_FORM_STATE);
    setNamePrefix(null);
    setNamePrefixWarning(false);
  };

  const handleChangeField = ({ fieldName, value }) => {
    setFormState((prevState) => {
      if (!isEmpty(value)) {
        return {
          ...prevState,
          selectedFileds: [
            ...prevState.selectedFileds?.filter((obj) => obj?.selectedFieldName !== fieldName),
            { selectedFieldName: fieldName, value },
          ],
        };
      }
      return {
        ...prevState,
        selectedFileds: prevState.selectedFileds?.filter((obj) => obj?.selectedFieldName !== fieldName),
      };
    });
  };

  const handleFormValuesChange = (_, values) => setFormValues({ values });

  const handleChangeCreativeDuration = (value) => {
    setFormState((prevState) => ({
      ...prevState,
      creativeDurationSelect: value.sort((a, b) => a - b),
    }));
  };

  const handleChangeWinnerAuction = ({ fieldName, value }) => {
    setFormState((prevState) => ({
      ...prevState,
      winnerAuctionSelect: value.sort((a, b) => a - b),
    }));
    handleChangeField({ fieldName, value });
  };

  const handleChangeProfile = (value) => {
    if (value) {
      form.setFieldValue(FORM_FIELDS.INPUT_VALUE.name, value);
    }
  };

  const handleProfilesFilter = (input, option) => {
    const filter = (str) => str.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    if (option.children) {
      return filter(option.children);
    } else if (option.label) {
      return filter(option.label);
    }
  };

  const getSupplySources = useCallback(() => {
    const allParametersMap = getParametersMap(snapshotParameters);
    if (!isEmpty(allParametersMap)) {
      return Object.keys(allParametersMap[FORM_FIELDS.SUPPLY_SOURCE.name]);
    }
    return [];
  }, [snapshotParameters]);

  const getOptionsByFieldName = useCallback(
    (fieldName) => {
      const lastSelectedParameter = selectedFileds?.at(-1);
      const prevSelectedParameter = selectedFileds?.at(-2);
      const lastSelectedParameterFieldName = lastSelectedParameter?.selectedFieldName;
      if (prevSelectedParameter && lastSelectedParameterFieldName === fieldName) {
        const isWinningAuction = lastSelectedParameterFieldName === FORM_FIELDS.WINNING_AUCTION.name;
        const options = getOptionsFromSnapshotParams(snapshotParameters, fieldName, selectedFileds);
        if (!options.includes(form.getFieldValue(lastSelectedParameterFieldName)) && !isWinningAuction) {
          form.resetFields([lastSelectedParameterFieldName]);
        }
        return options;
      }
      if (lastSelectedParameter) {
        return getOptionsFromSnapshotParams(snapshotParameters, fieldName, selectedFileds);
      }
      return [];
    },
    [form, selectedFileds, snapshotParameters]
  );

  const handleChangeInventoryType = (e) => {
    const value = e.target.value;
    if (value !== APP_INVENTORY_TYPE) {
      form.setFieldValue(FORM_FIELDS.DEVICE_FAMILY.name, null);
    }
    setFormState((prevState) => ({
      ...prevState,
      inventoryTypeValue: value,
      isInventoryTypeDisabled: false,
      isDeviceFamilyDisabled: value === WEB_INVENTORY_TYPE,
    }));
  };

  const handleChangeSelectedInventoryType = useCallback(() => {
    const appTypeOptions = getOptionsByFieldName(INVENTORY_TYPE_MAP[FORM_FIELDS.INVENTORY_TYPE.name]);
    const initialInventoryType = INITIAL_FORM_STATE.inventoryTypeValue;
    const appType = appTypeOptions?.length > 1 ? initialInventoryType : appTypeOptions[0];
    if (!appType && inventoryTypeValue !== initialInventoryType) {
      setFormState((prevState) => ({
        ...prevState,
        inventoryTypeValue: initialInventoryType,
        isInventoryTypeDisabled: false,
      }));
    }
    if (appType && inventoryTypeValue !== appType) {
      setFormState((prevState) => ({
        ...prevState,
        inventoryTypeValue: appType,
        isInventoryTypeDisabled: true,
      }));
    }
    if (appType && appType === initialInventoryType) {
      setFormState((prevState) => {
        if (
          prevState.inventoryTypeValue !== inventoryTypeValue &&
          prevState.selectedFileds?.[0]?.value === prevFirstSelectedParameter?.value
        ) {
          return {
            ...prevState,
            inventoryTypeValue,
            isInventoryTypeDisabled: false,
          };
        }
        return {
          ...prevState,
          isInventoryTypeDisabled: false,
        };
      });
    }
  }, [getOptionsByFieldName, inventoryTypeValue, prevFirstSelectedParameter]);

  const handleClearSupplySource = () => {
    if (selectedFileds?.some((field) => field?.selectedFieldName === FORM_FIELDS.SUPPLY_SOURCE.name)) {
      form.resetFields([...Object.keys(PARAMETER_NAMES)]);
      setFormState(({ creativeDurationSelect, createSnapshotFormActiveKeys }) => ({
        ...INITIAL_FORM_STATE,
        creativeDurationSelect,
        createSnapshotFormActiveKeys,
      }));
    }
  };

  const handleClearAppName = () => {
    if (
      selectedFileds?.some((field) => field?.selectedFieldName === FORM_FIELDS.APP_NAME.name) &&
      selectedFileds?.some((field) => field?.selectedFieldName === FORM_FIELDS.SUPPLY_SOURCE.name)
    ) {
      const { [FORM_FIELDS.SUPPLY_SOURCE.name]: supplySource, ...rest } = PARAMETER_NAMES;
      form.resetFields([...Object.keys(rest)]);
      setFormState(({ creativeDurationSelect, createSnapshotFormActiveKeys }) => ({
        ...INITIAL_FORM_STATE,
        selectedFileds: [
          {
            selectedFieldName: FORM_FIELDS.SUPPLY_SOURCE.name,
            value: form.getFieldValue(FORM_FIELDS.SUPPLY_SOURCE.name),
          },
        ],
        creativeDurationSelect,
        createSnapshotFormActiveKeys,
      }));
    }
  };

  const handleClearUserProfile = () => {
    form.setFieldValue(FORM_FIELDS.INPUT_VALUE.name, null);
  };

  const handleBidStateFilterOption = (input, option) =>
    (option?.children ?? '').toLowerCase().includes(input.toLowerCase());

  const handleChangeSelectType = (value) => {
    if (form.getFieldValue(FORM_FIELDS.USER_PROFILE.name)) {
      form.setFieldValue(FORM_FIELDS.USER_PROFILE.name, null);
      form.setFieldValue(FORM_FIELDS.INPUT_VALUE.name, null);
    }
    if (!value && form.getFieldValue(FORM_FIELDS.INPUT_VALUE.name)) {
      form.setFieldValue(FORM_FIELDS.INPUT_VALUE.name, null);
    }
  };

  const handleSnapshotNamePrefix = (newNamePrefix) => {
    const snapshotName = form.getFieldValue(FORM_FIELDS.SNAPSHOT_NAME.name);

    const startsWithOldPrefix = namePrefix && snapshotName.startsWith(namePrefix);
    const startsWithNewPrefix = newNamePrefix && snapshotName.startsWith(newNamePrefix);

    const isNewNameTooLong =
      newNamePrefix?.length + (startsWithOldPrefix ? snapshotName?.replace(namePrefix, '') : snapshotName)?.length >
      SNAPSHOT_NAME_MAX_LENGTH;

    if (!snapshotName || isNewNameTooLong || newNamePrefix === namePrefix) {
      setNamePrefixWarning(isNewNameTooLong);
      return form.validateFields([FORM_FIELDS.SNAPSHOT_NAME.name]);
    }

    if (startsWithOldPrefix || namePrefix?.startsWith(newNamePrefix)) {
      form.setFieldValue(FORM_FIELDS.SNAPSHOT_NAME.name, snapshotName.replace(namePrefix, newNamePrefix ?? ''));
    }
    if ((namePrefix && !startsWithOldPrefix && !startsWithNewPrefix) || (!namePrefix && newNamePrefix)) {
      form.setFieldValue(FORM_FIELDS.SNAPSHOT_NAME.name, `${newNamePrefix}${snapshotName}`);
    }
    setNamePrefix(newNamePrefix);
    form.validateFields([FORM_FIELDS.SNAPSHOT_NAME.name]);
  };

  const clearNamePrefixWarning = () => {
    setNamePrefixWarning(false);
  };

  useEffect(() => {
    if (selectedSnapshot) {
      const selectedSnapshotFormValues = selectedSnapshotToValues(selectedSnapshot);
      if (isEmpty(form.getFieldsValue())) {
        const creativeDuration =
          selectedSnapshotFormValues?.creativeDuration || INITIAL_FORM_STATE.creativeDurationSelect;
        const winningAuction = selectedSnapshotFormValues?.winningAuction || INITIAL_FORM_STATE.winnerAuctionSelect;

        form.setFieldsValue({ ...selectedSnapshotFormValues });
        setFormState((prevState) => ({
          ...prevState,
          creativeDurationSelect: creativeDuration?.sort((a, b) => a - b),
          winnerAuctionSelect: winningAuction?.sort((a, b) => a - b),
          createSnapshotFormActiveKeys: [
            USER_PARAMETERS_KEY,
            APP_PARAMETERS_KEY,
            CONTENT_PARAMETERS_KEY,
            DEMAND_PARAMETERS_KEY,
          ],
          selectedFileds: getSelectedFields(selectedSnapshotFormValues),
        }));
        setNamePrefix(
          createSnapshotNamePrefix([
            selectedSnapshotFormValues?.appName,
            selectedSnapshotFormValues?.country,
            selectedSnapshotFormValues?.selectionSearch?.inputValue,
          ])
        );
        const profile = [...profiles?.allUsers, ...profiles?.currentUser]?.find(
          ({ value }) => value === selectedSnapshotFormValues?.selectionSearch?.inputValue
        );
        if (profile) {
          form.setFieldValue(FORM_FIELDS.USER_PROFILE.name, profile?.value);
        }
      }
    }
  }, [form, selectedSnapshot, profiles]);

  useEffect(() => {
    form.setFieldsValue({ [FORM_FIELDS.INVENTORY_TYPE.name]: inventoryTypeValue });
  }, [inventoryTypeValue, form]);

  useEffect(() => {
    if (!form.getFieldValue(FORM_FIELDS.SNAPSHOT_NAME.name)) {
      form.setFieldsValue({ [FORM_FIELDS.SNAPSHOT_NAME.name]: `Drax_${Date.now()}` });
    }
  }, [form]);

  useEffect(() => {
    if (!isEmpty(selectedFileds) && prevSelectedFieldValue !== selectedFieldValue) {
      handleChangeSelectedInventoryType();
    }
  }, [handleChangeSelectedInventoryType, prevSelectedFieldValue, selectedFieldValue, selectedFileds]);

  useEffect(() => {
    if (selectType) {
      fetchProfiles();
    }
  }, [selectType, fetchProfiles]);

  useEffect(() => {
    const newNamePrefix = createSnapshotNamePrefix([publisher, country, deviceId]);
    if (newNamePrefix) {
      handleSnapshotNamePrefix(newNamePrefix);
    }
  }, [publisher, country, deviceId]);

  useEffect(() => {
    if (isDspDisabled) {
      form.setFieldValue(FORM_FIELDS.AD_PARTNER_DSP.name, null);
    }
  }, [isDspDisabled]);

  return {
    creativeDurationSelect,
    form,
    formInitialValues,
    profiles,
    isProfilesLoading,
    inventoryTypeValue,
    createSnapshotFormActiveKeys,
    winnerAuctionSelect,
    isInventoryTypeDisabled,
    isDeviceFamilyDisabled,
    namePrefixWarning,
    isDspDisabled,

    handleSubmit,
    handleSubmitFailed,
    handleChangeCreativeDuration,
    closeForm,
    handleFormValuesChange,
    handleChangeProfile,
    handleChangeInventoryType,
    handleClearSupplySource,
    handleClearAppName,
    handleClearUserProfile,
    onCreateSnapshotFormCollapseChange,
    handleProfilesFilter,
    handleChangeWinnerAuction,
    handleChangeField,
    handleBidStateFilterOption,
    getOptionsByFieldName,
    getSupplySources,
    handleChangeSelectType,
    clearNamePrefixWarning,
  };
};
