import React from 'react';
import { RowInfo } from '../Common/RowInfo';
import { StyledCard } from '../style';

export const InfoCard = ({ isLoading, data, title }) => {
  return (
    <StyledCard title={title} loading={isLoading}>
      {data?.map(({ label, value }) => (
        <RowInfo key={`${title}_${label}`} title={label} data={value} />
      ))}
    </StyledCard>
  );
};
