export const MEDIA_PASS = 'valid';
export const PARAMETER = 'stream-parameter';
export const VALUE = 'stream-parameter-value';
export const TYPE = 'stream-type';
export const ERROR_MESSAGE = 'message';
export const WARNING_MESSAGE = 'message';
export const VAST_PASS = 'valid';
export const AD_ID = 'ad-id';
export const VAST = 'parsable';
export const STATUS = 'status';
export const AD = 'ad-tag-valid';
export const INLINE = 'inline-tag-valid';
export const IMPRESSION = 'impression-url-valid';
export const CREATIVE = 'creative-tag-valid';
export const LINEAR = 'linear-creative-tag-valid';
export const MEDIA_FILE = 'media-file-tag-valid';
export const MEDIA_FILE_URL = 'media-file-url';
export const VPAID = 'vpaid-valid';
export const LOADING = 'loading';
export const COMPLETE = 'complete';
export const FAILURE = 'failure';
export const COUNTRY_CODES = [
  {
    value: 'CA',
    label: 'Canada',
  },
  {
    value: 'DK',
    label: 'Denmark',
  },
  {
    value: 'FR',
    label: 'France',
  },
  {
    value: 'DE',
    label: 'Germany',
  },
  {
    value: 'IT',
    label: 'Italy',
  },
  {
    value: 'NO',
    label: 'Norway',
  },
  {
    value: 'ES',
    label: 'Spain',
  },
  {
    value: 'SE',
    label: 'Sweden',
  },
  {
    value: 'CH',
    label: 'Switzerland',
  },
  {
    value: 'GB',
    label: 'United Kingdom',
  },
];
