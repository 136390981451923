import React from 'react';
import { RowInfo } from '../Common/RowInfo';
import { StyledCard } from '../style';

export const UserInfoCard = ({ isLoading, data }) => {
  const userInfoV2 = data?.['user-info'];
  const userInfoV3 = data?.viewer;
  return (
    <StyledCard title="User Info" loading={isLoading}>
      <RowInfo title="User ID" data={userInfoV2?.['user-id'] || userInfoV3?.identity?.['identity-id']} />
      <RowInfo title="User Profile ID" data={userInfoV2?.['profile-id'] || userInfoV3?.identity?.['profile-id']} />
    </StyledCard>
  );
};
