import React, { memo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Typography, Button } from '@hulu/react-style-components';
import styled from 'styled-components';
import { colors } from '@hulu/design-tokens';

import { AUCTION_SNAPSHOT_FILTERS_DATE_PICKER_FORMAT, EASTERN_TIME_ZONE } from '../../../../constants';
import { INITIAL_FILTERS_STATE } from '../../useAuctionSnapshot';
import { MomentDatePicker } from '../../../../utils';

const { RangePicker } = MomentDatePicker;

const StyledDatePickerWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-right: 4px;
  min-width: 252px;
  height: 32px;
  position: relative;
  & > span {
    text-transform: none;
    font-size: 14px;
    margin: 0 8px;
  }
`;
const StyledRangePicker = styled(RangePicker)`
  width: 300px;
  border-radius: 4px;
  &:hover {
    border-color: ${colors.colorBlack95};
  }
  .ant-picker-active-bar {
    display: none;
  }
`;

moment.tz.setDefault(EASTERN_TIME_ZONE);

const AuctionFiltersDatePickerComponent = (props) => {
  const { onChange, dateValue } = props;
  return (
    <StyledDatePickerWrapper>
      <Typography variant="button">Date</Typography>
      <StyledRangePicker
        format={AUCTION_SNAPSHOT_FILTERS_DATE_PICKER_FORMAT}
        onChange={onChange}
        value={dateValue}
        renderExtraFooter={() => (
          <Button variant="text" disabled={!dateValue.length} onClick={() => onChange(INITIAL_FILTERS_STATE.dateValue)}>
            Clear Dates
          </Button>
        )}
        getPopupContainer={(trigger) => trigger.parentNode}
      />
    </StyledDatePickerWrapper>
  );
};

AuctionFiltersDatePickerComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  clearDateRange: PropTypes.bool,
  handleClearDateRange: PropTypes.func,
};

export const AuctionFiltersDatePicker = memo(AuctionFiltersDatePickerComponent);
