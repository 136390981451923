import React from 'react';
import { Card, Checkbox, Collapse, Form, Input, Radio, Select, ConfigProvider } from 'antd';
import { Typography } from '@hulu/react-style-components';

import { AuctionErrorBoundary } from '../AuctionErrorBoundary';
import { TIME_FRAMES, DURATION_OPTIONS } from '../../../constants';
import {
  StyledDrawer,
  StyledCloseButton,
  StyledContentWrapper,
  StyledPaneFooter,
  StyledCancelButton,
  StyledSubmitButton,
  MultipleSelect,
  StyledFieldsWrapper,
  StyledHeadItemWrapper,
  StyledFooterWrapper,
  StyledSelectSearch,
  StyledProfileSelect,
} from './styles';
import { useAuctionSnapshotCreateForm } from './useAuctionSnapshotCreateForm';
import {
  FORM_FIELDS,
  INVENTORY_TYPES,
  RESULT_OPTIONS,
  DNT_OPTIONS,
  USER_PARAMETERS_KEY,
  APP_PARAMETERS_KEY,
  CONTENT_PARAMETERS_KEY,
  DEMAND_PARAMETERS_KEY,
  RECEIVED_BID_DEAL_ID,
  FIELD_DISABLED_BY_SUPPLY_SOURCE,
  DISNEY_PLUS,
  SNAPSHOT_NAME_PREFIX_WARNING,
} from './constants';
import { SNAPSHOT_NAME_MAX_LENGTH, INPUT_VALUE_MAX_LENGTH } from '../constants';
import { CAS } from '../AuctionSnapshotDetailedView/constants';

const { Option } = Select;
const { Panel } = Collapse;

const snapshotNameStyle = { margin: 0 };
const BID_STATE_INDEX = 0;
const WHITE_SPACE_CHECK = /\s/;

const renderOptions = (itemName) => (
  <Option value={itemName} key={itemName}>
    {itemName}
  </Option>
);

export const AuctionSnapshotCreateForm = ({
  isVisible,
  closeAdRequestDrawer,
  onSnapshotsRefresh,
  selectedSnapshot,
  setSelectedSnapshot,
  snapshotParameters,
  profileTypes,
}) => {
  const {
    form,
    formInitialValues,
    creativeDurationSelect,
    profiles,
    isProfilesLoading,
    inventoryTypeValue,
    createSnapshotFormActiveKeys,
    winnerAuctionSelect,
    isInventoryTypeDisabled,
    isDeviceFamilyDisabled,
    namePrefixWarning,
    isDspDisabled,

    closeForm,
    handleSubmitFailed,
    handleSubmit,
    handleChangeCreativeDuration,
    handleFormValuesChange,
    handleChangeProfile,
    handleChangeInventoryType,
    handleClearSupplySource,
    handleClearAppName,
    handleClearUserProfile,
    onCreateSnapshotFormCollapseChange,
    handleChangeWinnerAuction,
    handleProfilesFilter,
    handleChangeField,
    getOptionsByFieldName,
    getSupplySources,
    handleChangeSelectType,
    handleBidStateFilterOption,
    clearNamePrefixWarning,
  } = useAuctionSnapshotCreateForm({
    closeAdRequestDrawer,
    onSnapshotsRefresh,
    selectedSnapshot,
    setSelectedSnapshot,
    snapshotParameters,
  });

  const supplySource = form.getFieldValue(FORM_FIELDS.SUPPLY_SOURCE.name);
  const publisher = form.getFieldValue(FORM_FIELDS.APP_NAME.name);
  const userProfile = form.getFieldValue(FORM_FIELDS.USER_PROFILE.name);
  const bidStates = snapshotParameters?.snapshotSearchParametersByBidState?.[BID_STATE_INDEX]?.bidState;

  return (
    <StyledDrawer
      placement="right"
      open={isVisible}
      onClose={closeForm}
      title="Create Auction Snapshot"
      destroyOnClose
      width={600}
      closeIcon={<StyledCloseButton variant="outlined" icon="IconClose" />}
      footer={
        <StyledPaneFooter>
          <StyledCancelButton id="snapshot-cancel-button" size="medium" onClick={closeForm}>
            <Typography variant="body3">Cancel</Typography>
          </StyledCancelButton>
          <StyledSubmitButton id="snapshot-capture-button" form="createAdRequestForm" htmlType="submit">
            <Typography variant="body3">Capture</Typography>
          </StyledSubmitButton>
        </StyledPaneFooter>
      }
    >
      <AuctionErrorBoundary>
        <StyledContentWrapper>
          <ConfigProvider getPopupContainer={(trigger) => trigger.parentNode}>
            <Form
              id="createAdRequestForm"
              name="createAdRequestForm"
              onFinish={handleSubmit}
              onFinishFailed={handleSubmitFailed}
              layout="vertical"
              form={form}
              initialValues={formInitialValues}
              onValuesChange={handleFormValuesChange}
            >
              <StyledHeadItemWrapper>
                <Form.Item
                  initialValue={`Drax_${Date.now()}`}
                  label={FORM_FIELDS.SNAPSHOT_NAME.label}
                  name={FORM_FIELDS.SNAPSHOT_NAME.name}
                  rules={[
                    { required: true, message: 'Snapshot Name is required!' },
                    {
                      message: SNAPSHOT_NAME_PREFIX_WARNING,
                      warningOnly: true,
                      validator: () => {
                        if (namePrefixWarning) {
                          return Promise.reject();
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  style={snapshotNameStyle}
                >
                  <Input maxLength={SNAPSHOT_NAME_MAX_LENGTH} showCount allowClear onChange={clearNamePrefixWarning} />
                </Form.Item>
              </StyledHeadItemWrapper>
              <Collapse onChange={onCreateSnapshotFormCollapseChange} defaultActiveKey={createSnapshotFormActiveKeys}>
                <Panel header="User Parameters" key={USER_PARAMETERS_KEY}>
                  <StyledFieldsWrapper>
                    <Form.Item label={FORM_FIELDS.SELECT_TYPE.label} name={FORM_FIELDS.SELECT_TYPE.name}>
                      <Select
                        onChange={handleChangeSelectType}
                        placeholder="Select"
                        options={profileTypes}
                        allowClear
                      />
                    </Form.Item>
                    <Form.Item
                      label={FORM_FIELDS.USER_PROFILE.label}
                      name={FORM_FIELDS.USER_PROFILE.name}
                      loading={isProfilesLoading}
                    >
                      <StyledProfileSelect
                        showSearch
                        disabled={!form.getFieldValue(FORM_FIELDS.SELECT_TYPE.name) || isProfilesLoading}
                        optionFilterProp="label"
                        placeholder="Select"
                        onChange={handleChangeProfile}
                        filterOption={handleProfilesFilter}
                        onClear={handleClearUserProfile}
                        allowClear
                        loading={isProfilesLoading}
                      >
                        {profiles.currentUser && (
                          <Select.OptGroup label="My Profiles">
                            {profiles.currentUser.map((profile) => (
                              <Select.Option value={profile.value} key={profile.id}>
                                {profile.label}
                              </Select.Option>
                            ))}
                          </Select.OptGroup>
                        )}
                        <Select.OptGroup label="All Profiles">
                          {profiles.allUsers?.map((profile) => (
                            <Select.Option value={profile.value} key={profile.id}>
                              {profile.label}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                      </StyledProfileSelect>
                    </Form.Item>
                    <Form.Item
                      label={FORM_FIELDS.SUPPLY_SOURCE.label}
                      name={FORM_FIELDS.SUPPLY_SOURCE.name}
                      rules={[{ required: true, message: 'Supply Source is required!' }]}
                    >
                      <Select
                        placeholder="Select"
                        onClear={handleClearSupplySource}
                        onChange={(value) => {
                          handleChangeField({ fieldName: FORM_FIELDS.SUPPLY_SOURCE.name, value });
                          form.resetFields([FORM_FIELDS.COUNTRY.name]);
                        }}
                        allowClear
                      >
                        {(getOptionsByFieldName(FORM_FIELDS.SUPPLY_SOURCE.name).length > 0
                          ? getOptionsByFieldName(FORM_FIELDS.SUPPLY_SOURCE.name)
                          : getSupplySources()
                        )?.map((supplySource) => (
                          <Option value={supplySource} key={supplySource}>
                            {supplySource}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label={FORM_FIELDS.APP_NAME.label}
                      name={FORM_FIELDS.APP_NAME.name}
                      rules={[{ required: true, message: 'Publisher is required!' }]}
                    >
                      <Select
                        placeholder="Select"
                        disabled={!supplySource}
                        allowClear
                        onClear={handleClearAppName}
                        onChange={(value) => handleChangeField({ fieldName: FORM_FIELDS.APP_NAME.name, value })}
                      >
                        {getOptionsByFieldName(FORM_FIELDS.APP_NAME.name).map(renderOptions)}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label={FORM_FIELDS.COUNTRY.label}
                      name={FORM_FIELDS.COUNTRY.name}
                      rules={[{ required: supplySource === CAS, message: 'Country is required!' }]}
                    >
                      <Select
                        placeholder="Select"
                        disabled={
                          !supplySource ||
                          !publisher ||
                          FIELD_DISABLED_BY_SUPPLY_SOURCE?.[FORM_FIELDS?.COUNTRY?.name]?.[supplySource]
                        }
                        allowClear
                        onChange={(value) => handleChangeField({ fieldName: FORM_FIELDS.COUNTRY.name, value })}
                      >
                        {getOptionsByFieldName(FORM_FIELDS.COUNTRY.name).map(renderOptions)}
                      </Select>
                    </Form.Item>
                    <Form.Item label={FORM_FIELDS.ADVERTISER.label} name={FORM_FIELDS.ADVERTISER.name}>
                      <Input allowClear />
                    </Form.Item>
                    <Form.Item label={FORM_FIELDS.CREATIVE_ID.label} name={FORM_FIELDS.CREATIVE_ID.name}>
                      <Input allowClear />
                    </Form.Item>
                    <Form.Item
                      label={FORM_FIELDS.INPUT_VALUE.label}
                      name={FORM_FIELDS.INPUT_VALUE.name}
                      info="Info Text"
                      rules={[
                        {
                          required: !!userProfile,
                          message: `${FORM_FIELDS.INPUT_VALUE.label} is required!`,
                        },
                      ]}
                    >
                      <Input
                        disabled={userProfile || !form.getFieldValue(FORM_FIELDS.SELECT_TYPE.name)}
                        maxLength={INPUT_VALUE_MAX_LENGTH}
                        showCount
                        allowClear
                      />
                    </Form.Item>
                    <Form.Item label={FORM_FIELDS.SESSION_ID.label} name={FORM_FIELDS.SESSION_ID.name}>
                      <Input
                        disabled={
                          !supplySource ||
                          FIELD_DISABLED_BY_SUPPLY_SOURCE?.[FORM_FIELDS?.SESSION_ID?.name]?.[supplySource]
                        }
                        allowClear
                      />
                    </Form.Item>
                    <Form.Item label={FORM_FIELDS.RESULT.label} name={FORM_FIELDS.RESULT.name}>
                      <Radio.Group options={RESULT_OPTIONS} />
                    </Form.Item>
                  </StyledFieldsWrapper>
                </Panel>
                <Panel header="App Parameters" key={APP_PARAMETERS_KEY}>
                  <StyledFieldsWrapper>
                    <Form.Item
                      label={FORM_FIELDS.INVENTORY_TYPE.label}
                      name={FORM_FIELDS.INVENTORY_TYPE.name}
                      valuePropName="checked"
                    >
                      <Radio.Group value={inventoryTypeValue} disabled={!supplySource || isInventoryTypeDisabled}>
                        {INVENTORY_TYPES.map(({ value, label }) => (
                          <Radio
                            value={value}
                            key={value}
                            checked={inventoryTypeValue === value}
                            onChange={handleChangeInventoryType}
                          >
                            {label}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item label={FORM_FIELDS.DEVICE_FAMILY.label} name={FORM_FIELDS.DEVICE_FAMILY.name}>
                      <Select
                        placeholder="Select"
                        disabled={!supplySource || isDeviceFamilyDisabled}
                        allowClear
                        onChange={(value) => handleChangeField({ fieldName: FORM_FIELDS.DEVICE_FAMILY.name, value })}
                      >
                        {getOptionsByFieldName(FORM_FIELDS.DEVICE_FAMILY.name).map(renderOptions)}
                      </Select>
                    </Form.Item>
                    <Form.Item label={FORM_FIELDS.BRAND.label} name={FORM_FIELDS.BRAND.name}>
                      <Select
                        placeholder="Select"
                        allowClear
                        onChange={(value) => handleChangeField({ fieldName: FORM_FIELDS.BRAND.name, value })}
                      >
                        {getOptionsByFieldName(FORM_FIELDS.BRAND.name).map(renderOptions)}
                      </Select>
                    </Form.Item>
                    <Form.Item label={FORM_FIELDS.FORM_FACTOR.label} name={FORM_FIELDS.FORM_FACTOR.name}>
                      <Select
                        placeholder="Select"
                        allowClear
                        onChange={(value) => handleChangeField({ fieldName: FORM_FIELDS.FORM_FACTOR.name, value })}
                      >
                        {getOptionsByFieldName(FORM_FIELDS.FORM_FACTOR.name).map(renderOptions)}
                      </Select>
                    </Form.Item>
                    <Form.Item label={FORM_FIELDS.DEVICE_CATEGORY.label} name={FORM_FIELDS.DEVICE_CATEGORY.name}>
                      <Select
                        placeholder="Select"
                        allowClear
                        onChange={(value) => handleChangeField({ fieldName: FORM_FIELDS.DEVICE_CATEGORY.name, value })}
                      >
                        {getOptionsByFieldName(FORM_FIELDS.DEVICE_CATEGORY.name).map(renderOptions)}
                      </Select>
                    </Form.Item>
                    <Form.Item label={FORM_FIELDS.IFA_TYPE.label} name={FORM_FIELDS.IFA_TYPE.name}>
                      <Select
                        placeholder="Select"
                        disabled={!supplySource}
                        allowClear
                        onChange={(value) => handleChangeField({ fieldName: FORM_FIELDS.IFA_TYPE.name, value })}
                      >
                        {getOptionsByFieldName(FORM_FIELDS.IFA_TYPE.name).map(renderOptions)}
                      </Select>
                    </Form.Item>
                    <Form.Item label={FORM_FIELDS.DOMAIN.label} name={FORM_FIELDS.DOMAIN.name}>
                      <Input allowClear />
                    </Form.Item>
                    <Form.Item label={FORM_FIELDS.USER_AGENT.label} name={FORM_FIELDS.USER_AGENT.name}>
                      <Input allowClear />
                    </Form.Item>
                    <Form.Item label={FORM_FIELDS.DNT.label} name={FORM_FIELDS.DNT.name}>
                      <Radio.Group options={DNT_OPTIONS} />
                    </Form.Item>
                  </StyledFieldsWrapper>
                </Panel>
                <Panel header="Content Parameters" key={CONTENT_PARAMETERS_KEY}>
                  <StyledFieldsWrapper>
                    {/*
                    * Hidden until the BE part is ready *
                    <Form.Item label={FORM_FIELDS.SUPPLY_AD_UNIT_ID.label} name={FORM_FIELDS.SUPPLY_AD_UNIT_ID.name}>
                      <Input allowClear />
                    </Form.Item>
                    <Form.Item
                      label={FORM_FIELDS.SUPPLY_AD_UNIT_NAME.label}
                      name={FORM_FIELDS.SUPPLY_AD_UNIT_NAME.name}
                    >
                      <Input allowClear />
                    </Form.Item> */}
                    <Form.Item label={FORM_FIELDS.DISTRIBUTOR.label} name={FORM_FIELDS.DISTRIBUTOR.name}>
                      <Select
                        placeholder="Select"
                        allowClear
                        onChange={(value) => handleChangeField({ fieldName: FORM_FIELDS.DISTRIBUTOR.name, value })}
                      >
                        {getOptionsByFieldName(FORM_FIELDS.DISTRIBUTOR.name).map(renderOptions)}
                      </Select>
                    </Form.Item>
                    <Form.Item label={FORM_FIELDS.SERIES.label} name={FORM_FIELDS.SERIES.name}>
                      <Input
                        disabled={FIELD_DISABLED_BY_SUPPLY_SOURCE?.[FORM_FIELDS?.SERIES?.name]?.[supplySource]}
                        allowClear
                      />
                    </Form.Item>
                    <Form.Item label={FORM_FIELDS.ASSET_ID.label} name={FORM_FIELDS.ASSET_ID.name}>
                      <Input
                        disabled={FIELD_DISABLED_BY_SUPPLY_SOURCE?.[FORM_FIELDS?.ASSET_ID?.name]?.[supplySource]}
                        allowClear
                      />
                    </Form.Item>
                    <Form.Item
                      label={FORM_FIELDS.CONTENT_PARTNER.label}
                      name={FORM_FIELDS.CONTENT_PARTNER.name}
                      rules={[
                        {
                          validator: (_, value) => {
                            return WHITE_SPACE_CHECK.test(value) ? Promise.reject() : Promise.resolve();
                          },
                          message: `White spaces are not available for the ${FORM_FIELDS.CONTENT_PARTNER.label}!`,
                        },
                      ]}
                    >
                      <Input disabled={!supplySource} maxLength={100} showCount={!!supplySource} allowClear />
                    </Form.Item>
                    <Form.Item label={FORM_FIELDS.CHANNEL.label} name={FORM_FIELDS.CHANNEL.name}>
                      <Select
                        placeholder="Select"
                        disabled={!supplySource}
                        allowClear
                        onChange={(value) => handleChangeField({ fieldName: FORM_FIELDS.CHANNEL.name, value })}
                      >
                        {getOptionsByFieldName(FORM_FIELDS.CHANNEL.name).map(renderOptions)}
                      </Select>
                    </Form.Item>
                    <Form.Item label={FORM_FIELDS.VDM.label} name={FORM_FIELDS.VDM.name}>
                      <Select
                        placeholder="Select"
                        disabled={!supplySource}
                        allowClear
                        onChange={(value) => handleChangeField({ fieldName: FORM_FIELDS.VDM.name, value })}
                      >
                        {getOptionsByFieldName(FORM_FIELDS.VDM.name).map(renderOptions)}
                      </Select>
                    </Form.Item>
                  </StyledFieldsWrapper>
                </Panel>
                <Panel header="Demand Parameters" key={DEMAND_PARAMETERS_KEY}>
                  <StyledFieldsWrapper>
                    <Form.Item label={FORM_FIELDS.CREATIVE_DURATION.label} name={FORM_FIELDS.CREATIVE_DURATION.name}>
                      <MultipleSelect
                        placeholder="Select"
                        showSearch={false}
                        tagRender={({ label }) => <>&nbsp;{label}</>}
                        optionLabelProp="label"
                        mode="multiple"
                        showArrow
                        onChange={handleChangeCreativeDuration}
                        menuItemSelectedIcon={<></>}
                        allowClear
                      >
                        {DURATION_OPTIONS.map(({ label, value }) => (
                          <Option key={value} value={value} label={label}>
                            <Checkbox
                              checked={creativeDurationSelect.indexOf(value) >= 0}
                              style={{ pointerEvents: 'none' }}
                            >
                              {label + ' seconds'}
                            </Checkbox>
                          </Option>
                        ))}
                      </MultipleSelect>
                    </Form.Item>
                    <Form.Item label={FORM_FIELDS.BUYER_DEAL_ID.label} name={FORM_FIELDS.BUYER_DEAL_ID.name}>
                      <Input allowClear />
                    </Form.Item>
                    {/*
                    * Hidden until the BE part is ready *
                    <Form.Item label={FORM_FIELDS.DEAL_NAME.label} name={FORM_FIELDS.DEAL_NAME.name}>
                      <Input maxLength={100} allowClear />
                    </Form.Item>
                      */}
                    <Form.Item label={FORM_FIELDS.SYSTEM_DEAL_ID.label} name={FORM_FIELDS.SYSTEM_DEAL_ID.name}>
                      <Input allowClear />
                    </Form.Item>
                    {/*
                    * Hidden until the BE part is ready *
                    <Form.Item label={FORM_FIELDS.BUYER_SEAT_ID.label} name={FORM_FIELDS.BUYER_SEAT_ID.name}>
                      <Input allowClear />
                    </Form.Item>
                    <Form.Item label={FORM_FIELDS.PACKAGE_NAME.label} name={FORM_FIELDS.PACKAGE_NAME.name}>
                      <Input maxLength={100} allowClear />
                    </Form.Item>
                    <Form.Item label={FORM_FIELDS.CREATIVE_NAME.label} name={FORM_FIELDS.CREATIVE_NAME.name}>
                      <Input allowClear />
                    </Form.Item>*/}
                    <Form.Item label={FORM_FIELDS.BID_STATE.label} name={FORM_FIELDS.BID_STATE.name}>
                      <StyledSelectSearch
                        placeholder="Select"
                        filterOption={handleBidStateFilterOption}
                        allowClear
                        showSearch
                      >
                        {bidStates?.map((state) => renderOptions(state))}
                      </StyledSelectSearch>
                    </Form.Item>
                    <Form.Item label={FORM_FIELDS.MEDIA_FILE_URL.label} name={FORM_FIELDS.MEDIA_FILE_URL.name}>
                      <Input allowClear />
                    </Form.Item>
                    <Form.Item label={FORM_FIELDS.WINNING_AUCTION.label} name={FORM_FIELDS.WINNING_AUCTION.name}>
                      <MultipleSelect
                        placeholder="Select"
                        showSearch={false}
                        tagRender={({ label }) => <>&nbsp;{label}</>}
                        optionLabelProp="label"
                        mode="multiple"
                        showArrow
                        onChange={(value) =>
                          handleChangeWinnerAuction({ fieldName: FORM_FIELDS.WINNING_AUCTION.name, value })
                        }
                        menuItemSelectedIcon={<></>}
                        allowClear
                        disabled={!supplySource}
                      >
                        {getOptionsByFieldName(FORM_FIELDS.WINNING_AUCTION.name).map((itemName) => (
                          <Option key={itemName} value={itemName} label={itemName}>
                            <Checkbox
                              checked={winnerAuctionSelect.indexOf(itemName) >= 0}
                              style={{ pointerEvents: 'none' }}
                            >
                              {itemName}
                            </Checkbox>
                          </Option>
                        ))}
                      </MultipleSelect>
                    </Form.Item>
                    <Form.Item label={FORM_FIELDS.AD_PARTNER_DSP.label} name={FORM_FIELDS.AD_PARTNER_DSP.name}>
                      <Input disabled={isDspDisabled} allowClear />
                    </Form.Item>
                    <Form.Item
                      label={FORM_FIELDS.RECEIVED_BID_DEAL_ID.label}
                      name={FORM_FIELDS.RECEIVED_BID_DEAL_ID.name}
                    >
                      <Select placeholder="Select" options={RECEIVED_BID_DEAL_ID} allowClear />
                    </Form.Item>
                  </StyledFieldsWrapper>
                </Panel>
              </Collapse>
              <StyledFooterWrapper>
                <Card>
                  <Form.Item label={FORM_FIELDS.TIME_ALLOWED.label} name={FORM_FIELDS.TIME_ALLOWED.name}>
                    <Select placeholder="Select" options={TIME_FRAMES} allowClear />
                  </Form.Item>
                </Card>
              </StyledFooterWrapper>
            </Form>
          </ConfigProvider>
        </StyledContentWrapper>
      </AuctionErrorBoundary>
    </StyledDrawer>
  );
};
