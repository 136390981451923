import { useMemo } from 'react';
import { CAS, VDMS, DSS, CREATED_TIMESTAMP, REQUEST_TYPE_MAPPING } from '../constants';
import { timestampIntegrators } from '../timestampIntegrators';

const FIELDS_NAMES = {
  TIMESTAMP: 'Timestamp',
  TYPE: 'Supply Source',
  APP_NAME: 'Publisher',
  CHANNEL: 'Channel',
  DEVICE_TYPE: 'Device Type',
  BRAND: 'Brand',
  FORM_FACTOR: 'Form Factor',
  DEVICE_CATEGORY: 'Device Category',
  VDM: 'VDM',
  REQUEST_TYPE: 'Request Type',
};

const DEFAULT_SUPPLY_SOURCE_SUMMARY = [
  { label: FIELDS_NAMES.TIMESTAMP, value: '-' },
  { label: FIELDS_NAMES.TYPE, value: '-' },
  { label: FIELDS_NAMES.APP_NAME, value: '-' },
  { label: FIELDS_NAMES.CHANNEL, value: '-' },
  { label: FIELDS_NAMES.DEVICE_TYPE, value: '-' },
  { label: FIELDS_NAMES.BRAND, value: '-' },
  { label: FIELDS_NAMES.FORM_FACTOR, value: '-' },
  { label: FIELDS_NAMES.DEVICE_CATEGORY, value: '-' },
  { label: FIELDS_NAMES.VDM, value: '-' },
  { label: FIELDS_NAMES.REQUEST_TYPE, value: '-' },
];

const convertVDM = (data) => {
  try {
    if (data?.toString().includes('1')) return 'live';
    if (data?.toString().includes('0')) return 'lfv';
  } catch (error) {
    console.error(`convertVDM, ${error}`);
    throw new Error('VDM data is not available!');
  }
};

const vdmsSupplySourceSummary = (data) => [
  { label: FIELDS_NAMES.TIMESTAMP, value: timestampIntegrators(data?.[CREATED_TIMESTAMP]) },
  { label: FIELDS_NAMES.TYPE, value: VDMS },
  { label: FIELDS_NAMES.APP_NAME, value: data?.rawData?.app.name },
  { label: FIELDS_NAMES.CHANNEL, value: data?.rawData?.content?.ext?.channel },
  { label: FIELDS_NAMES.DEVICE_TYPE, value: data?.rawData?.device?.devicetype },
  { label: FIELDS_NAMES.VDM, value: convertVDM(data?.rawData?.content?.livestream) },
  { label: FIELDS_NAMES.REQUEST_TYPE, value: REQUEST_TYPE_MAPPING[VDMS] },
];

const dssSupplySourceSummary = (data) => [
  { label: FIELDS_NAMES.TIMESTAMP, value: timestampIntegrators(data?.[CREATED_TIMESTAMP]) },
  { label: FIELDS_NAMES.TYPE, value: DSS },
  { label: FIELDS_NAMES.APP_NAME, value: data?.rawData?.app.name },
  { label: FIELDS_NAMES.CHANNEL, value: data?.rawData?.app?.content?.ext?.channel },
  { label: FIELDS_NAMES.DEVICE_TYPE, value: data?.rawData?.device?.ext?.devicetype },
  { label: FIELDS_NAMES.VDM, value: convertVDM(data?.rawData?.app?.content?.livestream) },
  { label: FIELDS_NAMES.REQUEST_TYPE, value: REQUEST_TYPE_MAPPING[DSS] },
];

const casSupplySourceSummary = (data) => {
  const contentDataV2 = data?.rawData?.['content-info'];
  const contentDataV3 = data?.rawData?.content;

  const deviceDataV2 = data?.rawData?.['device-info'];
  const deviceDataV3 = data?.rawData?.device;

  return [
    { label: FIELDS_NAMES.TIMESTAMP, value: timestampIntegrators(data?.[CREATED_TIMESTAMP]) },
    { label: FIELDS_NAMES.TYPE, value: CAS },
    { label: FIELDS_NAMES.APP_NAME, value: contentDataV2?.publisher || contentDataV3?.publisher },
    { label: 'Device Name', value: deviceDataV2?.device || deviceDataV3?.name },
    { label: FIELDS_NAMES.BRAND, value: deviceDataV2?.['distribution-partner'] || deviceDataV3?.brand },
    { label: FIELDS_NAMES.FORM_FACTOR, value: deviceDataV2?.['distributor-platform'] || deviceDataV3?.['form-factor'] },
    { label: FIELDS_NAMES.DEVICE_CATEGORY, value: deviceDataV2?.category || deviceDataV3?.category },
    { label: FIELDS_NAMES.VDM, value: contentDataV2?.vdm || contentDataV3?.['playback-type'] },
    { label: FIELDS_NAMES.REQUEST_TYPE, value: REQUEST_TYPE_MAPPING[CAS] },
  ];
};

const supplySourceMap = {
  [VDMS]: vdmsSupplySourceSummary,
  [DSS]: dssSupplySourceSummary,
  [CAS]: casSupplySourceSummary,
};

const resolveSupplySourceSummary = (type, data) => {
  const fn = supplySourceMap[type];
  return fn ? fn(data) : DEFAULT_SUPPLY_SOURCE_SUMMARY;
};

export const useSupplySourceSummaryResolver = (type, data) => {
  return useMemo(() => resolveSupplySourceSummary(type, data), [type, data]);
};
