export const AD_ID_KEY = 'ad-id';
export const SOURCE_AD_ID_KEY = 'source-ad-id';
export const SOURCE_AD_IDS_KEY = 'source-ad-ids';
export const SOURCE_ASSET_ID_KEY = 'source-asset-id';
export const SOURCE_ASSET_IDS_KEY = 'source-asset-ids';
export const MEDIA_FILE_URL_KEY = 'mediafile-url';
export const BRAND_NAME_KEY = 'brand-name';
export const BRAND_NAMES_KEY = 'brand-names';
export const ADOMAIN_KEY = 'adomain';
export const ADOMAINS_KEY = 'adomains';
export const CAMPAIGN_MANAGER_NAME_KEY = 'campaign-manager-email';
export const UPDATED_AFTER_DATE_KEY = 'updated-after-date';
export const LAST_UPDATED_RANGE = 'last-updated-range';
export const UPDATED_AT_KEY = 'updated-at';
export const TRANSCODE_STATUS_KEY = 'transcode-status';
export const REVIEW_STATUS_KEY = 'review-status';
export const REVIEW_STATUSES_KEY = 'review-statuses';
export const ASSET_ID_KEY = 'asset-id';
export const ASSET_IDS_KEY = 'asset-ids';
export const ASSET_NAME_KEY = 'asset-name';
export const ASSET_DETAILS_KEY = 'asset-details';
export const DSP_KEY = 'dsp-name';
export const DSP_IDS_KEY = 'dsp-ids';
export const SOURCE_KEY = 'source';
export const SOURCES_KEY = 'sources';
export const REJECTION_REASONS_KEY = 'rejection-reasons';
export const COMMENT_KEY = 'rejection-comment';
export const INGESTION_TYPE_KEY = 'ingestion-type';
export const INGESTION_TYPES_KEY = 'ingestion-types';
export const CONTENT_PARTNER_ID_KEY = 'content-partner-id';
export const CONTENT_PARTNER_IDS_KEY = 'content-partner-ids';
export const CREATIVE_TYPE_KEY = 'creative-type';
export const CREATIVE_TYPES_KEY = 'creative-types';
export const PUBLISHER_KEY = 'publisher';
export const PUBLISHERS_KEY = 'publishers';
export const COUNTRY_CODES_KEY = 'country-codes';

// CT Filter Fields
export const CT_PUBLISHER_FILTER_FIELD = 'CT_PUBLISHER_FILTER_FIELD';
export const CT_AD_ID_FILTER_FIELD = 'CT_AD_ID_FILTER_FIELD';
export const CT_SOURCE_AD_ID_FILTER_FIELD = 'CT_SOURCE_AD_ID_FILTER_FIELD';
export const CT_SOURCE_ASSET_ID_FILTER_FIELD = 'CT_SOURCE_ASSET_ID_FILTER_FIELD';
export const CT_ASSET_ID_FILTER_FIELD = 'CT_ASSET_ID_FILTER_FIELD';
export const CT_BRAND_NAME_FILTER_FIELD = 'CT_BRAND_NAME_FILTER_FIELD';
export const CT_ADOMAIN_FILTER_FIELD = 'CT_ADOMAIN_FILTER_FIELD';
export const CT_DSP_FILTER_FIELD = 'CT_DSP_FILTER_FIELD';
export const CT_SOURCE_FILTER_FIELD = 'CT_SOURCE_FILTER_FIELD';
export const CT_REVIEW_STATUS_FILTER_FIELD = 'CT_REVIEW_STATUS_FILTER_FIELD';
export const CT_LAST_UPDATED_RANGE_FILTER_FIELD = 'CT_LAST_UPDATED_RANGE_FILTER_FIELD';
export const CT_CONTENT_PARTNER_FILTER_FIELD = 'CT_CONTENT_PARTNER_FILTER_FIELD';
export const CT_CAMPAIGN_MANAGER_EMAIL_FILTER_FIELD = 'CT_CAMPAIGN_MANAGER_EMAIL_FILTER_FIELD';
export const CT_MEDIAFILE_URL_FILTER_FIELD = 'CT_MEDIAFILE_URL_FILTER_FIELD';
export const CT_CREATIVE_TYPE_FILTER_FIELD = 'CT_CREATIVE_TYPE_FILTER_FIELD';
export const CT_INGESTION_TYPE_FILTER_FIELD = 'CT_INGESTION_TYPE_FILTER_FIELD';
export const CT_COUNTRY_FILTER_FIELD = 'CT_COUNTRY_FILTER_FIELD';

// CT Table Columns
export const CT_SOURCE_AD_ID_TABLE_COLUMN = 'CT_SOURCE_AD_ID_TABLE_COLUMN';
export const CT_SOURCE_ASSET_ID_TABLE_COLUMN = 'CT_SOURCE_ASSET_ID_TABLE_COLUMN';
export const CT_ASSET_ID_TABLE_COLUMN = 'CT_ASSET_ID_TABLE_COLUMN';
export const CT_MEDIAFILE_URL_TABLE_COLUMN = 'CT_MEDIAFILE_URL_TABLE_COLUMN';
export const CT_BRAND_NAME_TABLE_COLUMN = 'CT_BRAND_NAME_TABLE_COLUMN';
export const CT_ADOMAIN_TABLE_COLUMN = 'CT_ADOMAIN_TABLE_COLUMN';
export const CT_DSP_TABLE_COLUMN = 'CT_DSP_TABLE_COLUMN';
export const CT_SOURCE_TABLE_COLUMN = 'CT_SOURCE_TABLE_COLUMN';
export const CT_UPDATED_AT_TABLE_COLUMN = 'CT_UPDATED_AT_TABLE_COLUMN';
export const CT_STATUS_TABLE_COLUMN = 'CT_STATUS_TABLE_COLUMN';

// Map for filter fields
export const FILTER_FIELDS_MAP = {
  [PUBLISHERS_KEY]: CT_PUBLISHER_FILTER_FIELD,
  [AD_ID_KEY]: CT_AD_ID_FILTER_FIELD,
  [SOURCE_AD_IDS_KEY]: CT_SOURCE_AD_ID_FILTER_FIELD,
  [SOURCE_ASSET_IDS_KEY]: CT_SOURCE_ASSET_ID_FILTER_FIELD,
  [ASSET_IDS_KEY]: CT_ASSET_ID_FILTER_FIELD,
  [BRAND_NAMES_KEY]: CT_BRAND_NAME_FILTER_FIELD,
  [ADOMAINS_KEY]: CT_ADOMAIN_FILTER_FIELD,
  [DSP_IDS_KEY]: CT_DSP_FILTER_FIELD,
  [SOURCES_KEY]: CT_SOURCE_FILTER_FIELD,
  [REVIEW_STATUSES_KEY]: CT_REVIEW_STATUS_FILTER_FIELD,
  [LAST_UPDATED_RANGE]: CT_LAST_UPDATED_RANGE_FILTER_FIELD,
  [CONTENT_PARTNER_IDS_KEY]: CT_CONTENT_PARTNER_FILTER_FIELD,
  [CAMPAIGN_MANAGER_NAME_KEY]: CT_CAMPAIGN_MANAGER_EMAIL_FILTER_FIELD,
  [MEDIA_FILE_URL_KEY]: CT_MEDIAFILE_URL_FILTER_FIELD,
  [CREATIVE_TYPES_KEY]: CT_CREATIVE_TYPE_FILTER_FIELD,
  [INGESTION_TYPES_KEY]: CT_INGESTION_TYPE_FILTER_FIELD,
  [COUNTRY_CODES_KEY]: CT_COUNTRY_FILTER_FIELD,
};

// Map for table columns
export const TABLE_COLUMNS_MAP = {
  [SOURCE_AD_ID_KEY]: CT_SOURCE_AD_ID_TABLE_COLUMN,
  [SOURCE_ASSET_ID_KEY]: CT_SOURCE_ASSET_ID_TABLE_COLUMN,
  [ASSET_ID_KEY]: CT_ASSET_ID_TABLE_COLUMN,
  [MEDIA_FILE_URL_KEY]: CT_MEDIAFILE_URL_TABLE_COLUMN,
  [BRAND_NAMES_KEY]: CT_BRAND_NAME_TABLE_COLUMN,
  [ADOMAINS_KEY]: CT_ADOMAIN_TABLE_COLUMN,
  [DSP_KEY]: CT_DSP_TABLE_COLUMN,
  [SOURCE_KEY]: CT_SOURCE_TABLE_COLUMN,
  [UPDATED_AT_KEY]: CT_UPDATED_AT_TABLE_COLUMN,
  [REVIEW_STATUS_KEY]: CT_STATUS_TABLE_COLUMN,
};
