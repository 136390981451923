import React from 'react';
import { Alert } from 'antd';

import { RequestResponseCard } from '../Common/RequestResponseCard';
import { RequestParamsCard } from './RequestParamsCard';
import { AppDetailsCard } from './AppDetailsCard';
import { UserInfoCard } from './UserInfoCard';
import { CAS } from '../constants';
import { InfoCard } from './InfoCard';
import { useSupplySourceSummaryResolver } from './useSupplySourceSummaryResolver';
import { useSupplySourceDeviceDetailsResolver } from './useSupplySourceDeviceDetailsResolver';
import { useSupplySourceContentDetailsResolver } from './useSupplySourceContentDetailsResolver';

export const SupplySource = ({ isLoading, data, error, type }) => {
  const summaryData = useSupplySourceSummaryResolver(type, data);
  const deviceDetailsData = useSupplySourceDeviceDetailsResolver(type, data?.rawData);
  const contentDetailsData = useSupplySourceContentDetailsResolver(type, data?.rawData);

  return error ? (
    <Alert type="error" message={error.message || error} />
  ) : (
    <>
      <InfoCard title="Summary" isLoading={isLoading} data={summaryData} />
      {type !== CAS && <AppDetailsCard isLoading={isLoading} data={data?.rawData} />}
      <InfoCard title="Device Details" isLoading={isLoading} data={deviceDetailsData} />
      <InfoCard title="Content Details" isLoading={isLoading} data={contentDetailsData} />
      {type !== CAS && <RequestParamsCard isLoading={isLoading} data={data?.rawData} type={type} />}
      {type === CAS && <UserInfoCard isLoading={isLoading} data={data?.rawData} type={type} />}
      <RequestResponseCard isLoading={isLoading} data={data?.rawData} header="Request" />
    </>
  );
};
